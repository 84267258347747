// 査定：検索条件
export const SELL_CONDITIONS = [
  {
    paramKey: 's2',
    paramText: 'マウント',
    displayType: 'select'
  },
  {
    paramKey: 's3',
    paramText: 'カラー',
    displayType: 'select'
  },
  {
    paramKey: 's4',
    paramText: 'タイプ',
    displayType: 'select'
  },
  {
    paramKey: 's5',
    paramText: '無線LAN/Wi-Fi',
    displayType: 'check'
  },
  {
    paramKey: 's7',
    paramText: '雲台',
    displayType: 'check'
  },
  {
    paramKey: 'n2c',
    paramText: '光学ズーム',
    displayType: 'select'
  },
  {
    paramKey: 'n3c',
    paramText: '防水性能',
    displayType: 'check'
  },
  {
    paramKey: 'n4c',
    paramText: '防塵・防滴性能',
    displayType: 'check'
  },
  {
    paramKey: 'n5c',
    paramText: 'フィルターサイズ',
    displayType: 'select'
  },
  {
    paramKey: 'n6c',
    paramText: '高さ',
    displayType: 'select'
  },
  {
    paramKey: 'n1c',
    paramText: '有効画素数',
    displayType: 'check'
  }
];
