var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('detail-page-floating-cta',{attrs:{"tel1":"050-3033-0063","tel2":"tel:05030330063","line":"https://liff.line.me/1657473273-VDrRbxpv/89abc735548d4085901a2d71c43c1766"}})],1),(_vm.loaded.product && Object.keys(_vm.product).length)?_c('div',{class:{
      'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'container-narrow': _vm.$vuetify.breakpoint.smAndDown,
      sp: _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"id":"sell-detail-page"}},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.pageTitle))]),_c('breadcrumbs',{class:{
        'mx-2 mb-2': _vm.$vuetify.breakpoint.smAndDown
      },attrs:{"breadcrumbs":_vm.breadcrumbsList}}),_c('product-info',{staticClass:"content-area",attrs:{"categoryNm":_vm.parentCateNm,"product":_vm.product}}),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("初めての方にも安心！選べる3つの買取り")]),_c('div',{staticClass:"ec-back-color",class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('three-selling-ways',{attrs:{"sellDetail":true}})],1)]),(_vm.product.seriesPrds && _vm.product.seriesPrds.length)?_c('div',{staticClass:"content-area"},[_c('h2',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.product.makerName)+" "+_vm._s(_vm.product.itemName)+" "+_vm._s(_vm.parentCateNm)+"の買取り価格")]),_c('div',{class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('product-series',{attrs:{"parentCateNm":_vm.parentCateNm,"makerName":_vm.product.makerName,"seriesPrds":_vm.product.seriesPrds.slice(0, 2)}})],1)]):_vm._e(),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("高額買取りのポイント")]),_c('div',{class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('high-price-point')],1)]),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("当社が選ばれる4つのポイント")]),_c('div',{staticClass:"ec-back-color",class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('four-reasons')],1)]),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("カメラ買取りの流れ")]),_c('div',{class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('purchase-flow2')],1)]),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("お近くの店舗で買取り")]),_c('div',{class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('selling-store')],1)]),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("キャンペーンでお得に買取り")]),_c('div',{class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('promotion-banner')],1)]),_c('div',{staticClass:"content-area partition"},[_c('h2',{staticClass:"content-title"},[_vm._v("お問い合わせ総合窓口")]),_c('div',{staticClass:"ec-back-telephone"},[_c('div',{class:{
            'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
            'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
          }},[_c('telephone-application',{attrs:{"is-top":true}})],1)])])],1):(_vm.loaded.product && !Object.keys(_vm.product).length)?_c('not-found',{attrs:{"errorMessage":"この商品は買取が終了しているか、掲載がございません。"}}):_c('div',{staticClass:"loading-relative-list"},[_c('section-loading')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }