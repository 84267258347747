import { render, staticRenderFns } from "./maker-slider.vue?vue&type=template&id=90a54836&scoped=true&"
import script from "./maker-slider.vue?vue&type=script&lang=ts&"
export * from "./maker-slider.vue?vue&type=script&lang=ts&"
import style0 from "./maker-slider.vue?vue&type=style&index=0&id=90a54836&lang=scss&scoped=true&"
import style1 from "./maker-slider.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90a54836",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideGroup } from 'vuetify/lib/components/VSlideGroup';
import { VSlideItem } from 'vuetify/lib/components/VSlideGroup';
installComponents(component, {VSheet,VSlideGroup,VSlideItem})
