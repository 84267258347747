








































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';
import AppHeader from '@/components/header/app-header.vue';
import AppFooter from '@/components/footer/app-footer.vue';
import NavDrawerMenu from '@/components/header/nav-drawer-menu/nav-drawer-menu.vue';
import OverlayLoading from '@/components/common/overlay-loading.vue';
import errorDialog from './components/common/error-dialog.vue';

export default Vue.extend({
  name: 'app',
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    'nav-drawer-menu': NavDrawerMenu,
    'overlay-loading': OverlayLoading,
    'error-dialog': errorDialog
  },
  setup: (props, context) => {
    const state = reactive({
      drawer: false,
      rendered: false,
      isSellListPage: false
    });

    // overlayの左上閉じるボタンの表示制御用
    watch(
      () => context.root.$route.name,
      () => {
        state.isSellListPage = context.root.$route.name?.includes('sell-list') ? true : false;
      },
      { immediate: true }
    );

    // routerイベントをwatch
    watch(
      () => context.root.$store.loader.isRouteLoaded,
      (isRouteLoaded) => {
        state.rendered = isRouteLoaded;
      },
      { deep: true, immediate: true }
    );

    /**
     * スマホ版のナビゲーションメニューを閉じる
     */
    const closeMenu = () => (state.drawer = false);

    return {
      closeMenu,
      ...toRefs(state)
    };
  }
});
