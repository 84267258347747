import { reactive } from '@vue/composition-api';
import { NewerCategory, SellCategory, UsedCategory } from '@/types/tsv-config';
import WebStorage from '@/logic/web-storage';
import NsSysConfigsService from '@/logic/tsv/ns-list-sys-configs.service';

export default function categoryStore() {
  const NEWER_KEY = 'NewerCategory';
  const USED_KEY = 'UsedCategory';
  const SELL_KEY = 'SellCategory';

  const state = reactive({
    newerCategory: [] as Array<NewerCategory>,
    usedCategory: [] as Array<UsedCategory>,
    sellCategory: [] as Array<SellCategory>
  });

  return {
    /**
     * 新品カテゴリー関連処理
     */
    get newerCategory(): Array<NewerCategory> {
      if (!state.newerCategory || !state.newerCategory.length) state.newerCategory = WebStorage.getSessionStorage(NEWER_KEY);
      return state.newerCategory ? state.newerCategory : [];
    },

    get newerCategoryOnlyParent(): Array<NewerCategory> {
      const parentCategory = state.newerCategory ? state.newerCategory.filter((cate) => cate.digit === '4') : [];
      return parentCategory.length ? parentCategory : [];
    },

    async fetchNewerCategories() {
      try {
        state.newerCategory = await NsSysConfigsService.fetchNewerCategories();
        WebStorage.setSessionStorage(NEWER_KEY, state.newerCategory);
      } catch (error) {
        console.error(error);
        state.newerCategory = [];
      }
    },

    searchNewerCategoryByConcatenationName(name: string): NewerCategory | {} {
      return state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0]
        ? state.newerCategory.filter((item: NewerCategory) => item.concatenationCategoryName === name)[0]
        : {};
    },

    /**
     * 中古カテゴリー関連処理
     */
    get usedCategory(): Array<UsedCategory> {
      if (!state.usedCategory || !state.usedCategory.length) state.usedCategory = WebStorage.getSessionStorage(USED_KEY);
      return state.usedCategory ? state.usedCategory : [];
    },

    get usedCategoryOnlyParent(): Array<UsedCategory> {
      const parentCategory = state.usedCategory ? state.usedCategory.filter((cate) => !cate.categoryName.includes(':')) : [];
      return parentCategory.length ? parentCategory : [];
    },

    async fetchUsedCategories() {
      try {
        state.usedCategory = await NsSysConfigsService.fetchUsedCategories();
        WebStorage.setSessionStorage(USED_KEY, state.usedCategory);
      } catch (error) {
        console.error(error);
        state.usedCategory = [];
      }
    },

    /**
     * 買取りカテゴリー関連処理
     */
    get sellCategory(): Array<SellCategory> {
      if (!state.sellCategory || !state.sellCategory.length) state.sellCategory = WebStorage.getSessionStorage(SELL_KEY);
      return state.sellCategory ? state.sellCategory : [];
    },

    async fetchSellCategories() {
      try {
        state.sellCategory = await NsSysConfigsService.fetchSellCategories();
        WebStorage.setSessionStorage(SELL_KEY, state.sellCategory);
      } catch (error) {
        console.error(error);
        state.sellCategory = [];
      }
    }
  };
}

export type CategoryStore = ReturnType<typeof categoryStore>;
