var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.breadcrumbs.length)?_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}):_vm._e(),_c('div',{staticClass:"main-content-area",class:{
      'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'container-narrow': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"id":"main-content"}},[_c('div',{class:{
        'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('span',{staticClass:"content-title",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        },attrs:{"id":"quote-select-title"}},[_vm._v("見積り方法を選択")]),_c('div',{staticClass:"quote-select"},[_c('v-btn',{staticClass:"quote-select-btn",class:{ active: _vm.selectedQuote === _vm.QUOTE_TYPE.COUNSELING_TO_OPERATOR },attrs:{"dark":"","x-large":_vm.$vuetify.breakpoint.mdAndUp,"large":_vm.$vuetify.breakpoint.smAndDown,"elevation":"0"},on:{"click":function($event){_vm.selectedQuote = _vm.QUOTE_TYPE.COUNSELING_TO_OPERATOR;
            _vm.$vuetify.goTo(_vm.target, _vm.options);}}},[_c('span',{staticClass:"quote-select-btn-text"},[_c('v-icon',{class:{
                'mr-2': _vm.$vuetify.breakpoint.mdAndUp
              },attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("fas fa-user-headset")]),_c('span',[_vm._v("オペレーターに相談")])],1)]),_c('v-btn',{staticClass:"quote-select-btn",class:{ active: _vm.selectedQuote === _vm.QUOTE_TYPE.SEARCH_KEYWORD },attrs:{"dark":"","x-large":_vm.$vuetify.breakpoint.mdAndUp,"large":_vm.$vuetify.breakpoint.smAndDown,"elevation":"0"},on:{"click":function($event){_vm.selectedQuote = _vm.QUOTE_TYPE.SEARCH_KEYWORD;
            _vm.$vuetify.goTo(_vm.target, _vm.options);}}},[_c('span',{staticClass:"quote-select-btn-text"},[_c('v-icon',{class:{
                'mr-2': _vm.$vuetify.breakpoint.mdAndUp
              },attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v("far fa-search")]),_c('span',[_vm._v("キーワードで調べる")])],1)])],1)]),_c('div',{staticStyle:{"padding":"0 1rem"}},[_c('div',{staticClass:"ec-back-color",class:{
          'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
          'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
        }},[(_vm.selectedQuote === _vm.QUOTE_TYPE.COUNSELING_TO_OPERATOR)?_c('quick-assessment',{attrs:{"is-sell":true}}):_vm._e(),(_vm.selectedQuote === _vm.QUOTE_TYPE.SEARCH_KEYWORD)?_c('check-estimation'):_vm._e()],1)])]),_c('sub-contents',{attrs:{"is-display-quick-assessment":false}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"floating-banner",attrs:{"id":"floating-banner"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"floating-banner--pc"},[_vm._m(0)]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{staticClass:"floating-banner--sp"},[_vm._m(1)]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/ec/page/service/syuttyou-kaitori"}},[_c('img',{attrs:{"src":"https://www.net-chuko.com/ec/images2/pages/common/syuttyou-kaitori_920x120_a.png","alt":"出張買取"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"/ec/page/service/syuttyou-kaitori"}},[_c('img',{attrs:{"src":"https://www.net-chuko.com/ec/images2/pages/common/syuttyou-kaitori_240x120_a.png","alt":"出張買取"}})])}]

export { render, staticRenderFns }