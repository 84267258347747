export const SUGGEST_CATEGORY_LIST: Set<string> = new Set([
  'カメラ用品',
  'コンパクトデジタルカメラ',
  'デジタルビデオカメラ',
  'デジタル一眼レフ',
  'ビデオカメラ',
  'フィルムカメラ',
  'ミラーレス一眼',
  '三脚',
  '交換レンズ'
]);
