

















import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import PurchaseInfoTop from '@/components/page/service/purchase-info/purchase-info-top.vue';
import '@/styles/page.scss';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'purchase-info',
  components: {
    'purchase-info-top': PurchaseInfoTop
  },
  setup: (_, context) => {
    document.title = '高価買取り | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'ご自宅に眠っているカメラははありませんか?まずは簡単見積り！(無料)')

    const state = reactive({
    });


    return {
      ...toRefs(state),
    };
  }
});
