











































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import '@/styles/page.scss';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'service-used-6months-warranty',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (_, context) => {
    document.title = '中古カメラ6ヶ月保証規定 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '中古カメラ・中古デジカメ・中古レンズ中古ビデオカメラのお買い上げに適応する、中古カメラ6ヶ月保証規定です')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '中古カメラ6ヶ月保証規定',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
