// 査定商品一覧の表示件数
export const DISPLAY_COUNT_LIST = [
  {
    text: '20件',
    value: 20
  },
  {
    text: '40件',
    value: 40
  },
  {
    text: '100件',
    value: 100
  }
];
