





























import Vue from 'vue';
import { reactive, toRefs, PropType } from '@vue/composition-api';
import { ModelPrds } from '@/types/sell-product-detail';
import { noimage, roundDownSellPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'product-series',
  props: {
    // カテゴリ名
    parentCateNm: {
      required: true,
      default: '',
      type: String
    },
    // シリーズ商品情報
    makerName: {
      required: true,
      default: '',
      type: String
    },
    // シリーズ商品情報
    seriesPrds: {
      required: true,
      default: [],
      type: Array as PropType<Array<ModelPrds>>
    }
  },
  setup: (props, context) => {
    const state = reactive({});

    const toDetail = (janCode: string, usedQuotesCode: string) => {
      context.root.$router.push({ path: `/ec/sell/${janCode}` });
    };

    return {
      ...toRefs(state),
      noimage,
      roundDownSellPrice,
      toDetail
    };
  }
});
