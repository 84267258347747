

































































import Vue from 'vue';
import dayjs from 'dayjs';
import { reactive, toRefs, PropType, computed } from '@vue/composition-api';
import { noimage } from '@/logic/utils';
import { SavedEstimate } from '@/types/estimate-list';

export default Vue.extend({
  name: 'estimate-info',
  props: {
    estimate: {
      type: Object as PropType<SavedEstimate>,
      required: true
    }
  },
  components: {},
  setup: (props, context) => {
    const state = reactive({});

    /**
     * 有効期限切れ
     */
    const expired = computed(() => {
      const estimate = props.estimate as SavedEstimate;
      return dayjs().isAfter(dayjs(estimate.expirationDate), 'day');
    });

    /**
     * 削除
     */
    const remove = (id: string) => {
      context.emit('remove', id);
    };

    return {
      ...toRefs(state),
      noimage,
      expired,
      remove
    };
  }
});
