












import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'high-price-point',
  setup: () => {
    const state = reactive({
      highPricePoint: [
        {
          title: '動作確認',
          desc: 'ダイヤルがしっかり回るか・反応するか、撮影映像がメディアに正しく記録されるかなど、ベース動作のチェックをします。'
        },
        {
          title: '劣化箇所',
          desc: 'グリップの樹脂はベタベタしないか、レンズにカビが生えていないか曇りはないかなど、付属品含め劣化している箇所のチェックをします。'
        },
        {
          title: '清潔さ',
          desc:
            'ファインダー部分にホコリは溜まっていないか、マウント部分にホコリは溜まっていないかなど、動作・見た目に大きな影響は与えない箇所でもチェックをします。'
        },
        {
          title: '付属品の有無',
          desc: '箱や、説明書、インストールディスク、バッテリーなど付属品が揃っているかもチェックします。'
        }
      ]
    });

    return {
      ...toRefs(state)
    };
  }
});
