







































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { noimage, roundDownSellPrice } from '@/logic/utils';

export default Vue.extend({
  name: 'estimate-product',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  setup: () => {
    const state = reactive({
      netshopUrl: process.env.VUE_APP_NET_SHOP_URL
    });

    return {
      ...toRefs(state),
      noimage,
      roundDownSellPrice
    };
  }
});
