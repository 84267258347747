




































































































































































import Vue from 'vue';
import { ref, onMounted } from '@vue/composition-api';
import HighPricePd from '@/components/high-price-pd.vue';
import IncludeFIleService from '@/logic/include-file.service';
import ThreeSellingWays from '@/components/three-selling-ways.vue';

export default Vue.extend({
  name: 'sub-contents',
  components: {
    'high-price-pd': HighPricePd,
    'three-selling-ways': ThreeSellingWays
  },
  props: {
    isDisplayBanner: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisplayHighPricePd: {
      type: Boolean,
      required: false,
      default: true
    },
    isDisplayPublicRelations: {
      type: Boolean,
      required: false,
      default: true
    },
    isThreeSellingWays: {
      type: Boolean,
      required: false,
      default: true
    },
    isTopThreeSellingWays: {
      type: Boolean,
      required: false,
      default: false
    },
    topThreeSellingWaysTitle: {
      type: String,
      required: false,
      default: '３つの買取り方法'
    },
    isDisplayQuickAssessment: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup: () => {
    const includeHtmlBanner = ref<string>('');
    const includeHtmlPd = ref<string>('');

    onMounted(() => {
      // インクルードファイルを取得
      IncludeFIleService.fetchIncludeFile('sell/top/banner1.html').then((response) => (includeHtmlBanner.value = response));
      IncludeFIleService.fetchIncludeFile('sell/top/banner2.html').then((response) => (includeHtmlPd.value = response));
    });

    return {
      includeHtmlBanner,
      includeHtmlPd
    };
  }
});
