






















































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'service-link',
  setup: () => {
    const state = reactive({
      selectedLink: null,
      linkList: [
        {
          text: '買取り・下取り',
          url: 'https://www.net-chuko.com/ec/page/service/sell-guide'
        },
        {
          text: 'なんでも下取り',
          url: 'https://shop.kitamura.jp/ec/guide/shitadori'
        },
        {
          text: 'トクトク交換',
          url: 'https://shop.kitamura.jp/ec/special/general/tokutokukoukan'
        },
        {
          text: 'お支払い方法',
          url: 'https://shop.kitamura.jp/sitemap/riyou_shiharai_index.html'
        },
        {
          text: '5年間保証',
          url: 'https://shop.kitamura.jp/sitemap/riyou_hoshou_index.html'
        },
        {
          text: '修理のご案内',
          url: 'https://shop.kitamura.jp/files/syuuri/index.html'
        },
        {
          text: '送料・手数料',
          url: 'https://shop.kitamura.jp/sitemap/riyou_souryou_index.html'
        },
        {
          text: '返品・交換',
          url: 'https://shop.kitamura.jp/sitemap/riyou_henpin_index.html'
        },
        {
          text: '商品お届け・納期',
          url: 'https://shop.kitamura.jp/sitemap/riyou_otodoke_index.html'
        },
        {
          text: 'セール・特集一覧',
          url: 'https://shop.kitamura.jp/ec/special/sale-fair'
        },
      ]
    });

    return {
      ...toRefs(state)
    };
  }
});
