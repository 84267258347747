








































































































































































































































































































































































































































import Vue from 'vue';
import { onMounted, reactive, toRefs } from '@vue/composition-api';
import suggestService from '../logic/suggest.service';
import { AssessmentItem } from '../shims-db';

export default Vue.extend({
  name: 'quick-assessment',
  components: {},
  props: {
    isSell: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: () => {
    const state = reactive({
      assessmentUrl: process.env.VUE_APP_ASSESSMENT_URL + 'sell/quick-assessment2.do',
      isValidForm: true,
      products: [{}],
      lastName: '',
      firstName: '',
      prefectureList: [
        { header: '北海道・東北地方' },
        '北海道',
        '青森県',
        '岩手県',
        '秋田県',
        '宮城県',
        '山形県',
        '福島県',
        { header: '関東地方' },
        '栃木県',
        '群馬県',
        '茨城県',
        '埼玉県',
        '東京都',
        '千葉県',
        '神奈川県',
        { header: '中部地方' },
        '山梨県',
        '長野県',
        '新潟県',
        '富山県',
        '石川県',
        '福井県',
        '静岡県',
        '岐阜県',
        '愛知県',
        { header: '近畿地方' },
        '三重県',
        '滋賀県',
        '京都府',
        '大阪府',
        '兵庫県',
        '奈良県',
        '和歌山県',
        { header: '四国地方' },
        '徳島県',
        '香川県',
        '愛媛県',
        '高知県',
        { header: '中国地方' },
        '鳥取県',
        '島根県',
        '岡山県',
        '広島県',
        '山口県',
        { header: '九州・沖縄地方' },
        '福岡県',
        '佐賀県',
        '長崎県',
        '大分県',
        '熊本県',
        '宮崎県',
        '鹿児島県',
        '沖縄県'
      ],
      phoneNumber: '',
      makerList: [{}],
      assessmentItemList: [{ category: '', manufacturer: '', itemName: '' }],
      isAgree: false,
      prefecture: ''
    });
    async function setMenuList() {
      const quotation = await suggestService.getQuotation();
      state.products = suggestService.getCategory(quotation.category);
      state.makerList = suggestService.getMakerList(quotation.maker);
    }

    function addProduct() {
      if (state.assessmentItemList.length < 3) {
        state.assessmentItemList.push({ category: '', manufacturer: '', itemName: '' });
        // state.productCount = state.productCount + 1;
      }
    }

    function deleteProduct(index: number) {
      if (index > 1 && index <= state.assessmentItemList.length) {
        state.assessmentItemList.splice(index - 1, 1);
      }
    }

    // フォームバリデーション
    // 入力必須
    const requiredRule = (v: any) => !!v || '必須項目です';

    // 全角かなのみ
    const fullSizeKanaRule = (v: any) => /^[\u3040-\u309f]*$/g.test(v) || 'ひらがなで入力してください';

    // 0から始まる10～11桁の半角数字のみ
    const phoneNumberRule = (v: any) => /^0\d{9,10}$/g.test(v) || '半角数字で電話番号を入力してください';

    function validatePhoneNumber(phoneNumber: any): boolean {
      if (phoneNumber === null) return false;
      return !isNaN(phoneNumber);
    }

    function validateAssessmentItems(itemList: AssessmentItem[]): boolean {
      if (itemList[0].category !== '' && itemList[0].manufacturer !== '') {
        return true;
      }
      return false;
    }

    function validateForm(isValid: boolean, event: Event): void {
      if (!isValid) {
        event.preventDefault();
      }
    }

    /**
     * Only Hiragana
     */
    function validateName(name: string): boolean {
      if (name === null || name === '') return false;
      const nameReg = /^[\u3040-\u309f]*$/g;
      return nameReg.test(name.trim());
    }

    function isEmpty(stringData: any) {
      if (stringData === '' || stringData === null) return true;
      return false;
    }

    function validateAssessmentForm(): boolean {
      return (
        !isEmpty(state.prefecture) &&
        validateName(state.firstName) &&
        validateName(state.lastName) &&
        validatePhoneNumber(state.phoneNumber) &&
        validateAssessmentItems(state.assessmentItemList) &&
        state.isAgree
      );
    }

    async function applyAssessment() {
      if (validateAssessmentForm()) {
        const kencode = state.prefecture;
        await suggestService.applyAssessment(state.assessmentItemList, kencode, state.lastName, state.firstName, state.phoneNumber);
      }
    }

    setMenuList();

    onMounted(async () => {
      await suggestService.priorAccessAssessment();
    });

    return {
      ...toRefs(state),
      setMenuList,
      addProduct,
      deleteProduct,
      applyAssessment,
      validatePhoneNumber,
      validateName,
      validateAssessmentItems,
      validateAssessmentForm,
      requiredRule,
      fullSizeKanaRule,
      phoneNumberRule,
      validateForm
    };
  }
});
