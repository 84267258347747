




import { reactive, toRefs } from '@vue/composition-api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'businessHours',
  components: {},
  setup: () => {
    const state = reactive({
      model: null,
      baseURL: process.env.VUE_APP_BASE_URL
    });

    return {
      ...toRefs(state)
    };
  }
});
