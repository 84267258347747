





































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'my-page-menu',
  setup: () => {
    const state = reactive({
      nsBaseUrl: process.env.VUE_APP_NET_SHOP_URL,
      accountUpdateUrl: process.env.VUE_APP_API_MEMBER_BASE_URL + 'group/account/update.do'
    });

    return {
      ...toRefs(state)
    };
  }
});
