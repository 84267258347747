


















import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-item-detail',
  data() {
    return {
      syuttyouKaitoriItems: [
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item01.jpg',
          text: '交換レンズ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item02.jpg',
          text: 'ミラーレス一眼／\nデジタル一眼レフ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item03.jpg',
          text: 'コンパクト\nデジタルカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item04.jpg',
          text: 'フィルムカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item05.jpg',
          text: 'ビデオカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item06.jpg',
          text: 'カメラ用品'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item07.jpg',
          text: '腕時計'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item08.jpg',
          text: 'ブランド品',
          text2: '※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item09.jpg',
          text: '切手',
          text2: '※シートのみ(10面以上)買取\n※関東・関西エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item10.jpg',
          text: '貴金属',
          text2: '※ルース(裸石)は買取不可\n※関東・関西エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item11.jpg',
          text: 'レコード',
          text2: '※LPのみの買取\n※関東エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item12.jpg',
          text: '記念金貨',
          text2: '※関東・関西エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item13.jpg',
          text: '着物',
          text2: '※関東エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item14.jpg',
          text: '毛皮',
          text2: '※関東・関西エリアのみ対応\n※出張買取のみ買取'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item15.jpg',
          text: 'お酒',
          text2: '※関東エリアのみ対応\n※出張買取のみ買取'
        }
      ]
    };
  },

});

