





























import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import SyuttyouKaitoriFloatingBtn from '@/components/page/service/syuttyou-kaitori/syuttyou-kaitori-floating-btn.vue';
import SyuttyouKaitoriTop from '@/components/page/service/syuttyou-kaitori/syuttyou-kaitori-top.vue';
import '@/styles/page.scss';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'syuttyou-kaitori',
  components: {
    breadcrumbs: Breadcrumbs,
    'syuttyou-kaitori-floating-btn': SyuttyouKaitoriFloatingBtn,
    'syuttyou-kaitori-top': SyuttyouKaitoriTop
  },
  setup: (_, context) => {
    document.title = 'カメラ・交換レンズの出張買取 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '電話1本でお伺いし、カメラ･交換レンズなど撮影機材をその場で現金買取り! 数が多くて店舗に持ち込めない場合や、現金が早く必要な場合は出張買取がおすすめ')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '出張買取',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
