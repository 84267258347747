














import Vue from 'vue';
import { onMounted, reactive, toRefs, watch } from '@vue/composition-api';
import { Maker } from '@/types/tsv-config';
import ProductListConfigsService from '@/logic/tsv/product-list-configs.service';

export default Vue.extend({
  name: 'maker-slider',
  components: {},
  props: {
    category: {
      type: String,
      required: true
    }
  },
  setup: (props) => {
    const state = reactive({
      makerMap: new Map<string, Maker[]>(),
      makerList: [] as Maker[]
    });

    const filterMaker = () => {
      const category = props.category as string;
      if (category) state.makerList = state.makerMap.get(category) || [];
    };

    onMounted(async () => {
      // メーカー一覧取得
      const makerList = await ProductListConfigsService.fetchMakers();
      makerList.forEach((maker) => {
        if (state.makerMap.has(maker.categoryName)) {
          const list = state.makerMap.get(maker.categoryName);
          list?.push(maker);
        } else {
          state.makerMap.set(maker.categoryName, [maker]);
        }
      });
      // Vue2にで、Mapは検知対象外のため、ここでフィルターする必要あり
      filterMaker();
    });

    watch(
      () => [props.category],
      () => {
        filterMaker();
      }
    );

    return {
      ...toRefs(state)
    };
  }
});
