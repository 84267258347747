const WebStorage = {
  /**
   * SessionStorageへの格納
   * @param key キー名
   * @param value 値
   */
  setSessionStorage(key: string, value: any) {
    const convertJson = JSON.stringify(value);
    sessionStorage.setItem(key, convertJson);
  },

  /**
   * SessionStorageへの格納
   * @param key キー名
   * @param value 値
   */
  getSessionStorage(key: string): any {
    const jsonVal = sessionStorage.getItem(key);
    const val = jsonVal ? JSON.parse(jsonVal) : undefined;
    return val;
  },

  /**
   * SessionStorageから削除
   * @param key キー名
   */
  removeSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  },

  /**
   * LocalStorageへの格納
   * @param key キー名
   * @param value 値
   */
  setLocalStorage(key: string, value: any) {
    const convertJson = JSON.stringify(value);
    localStorage.setItem(key, convertJson);
  },

  /**
   * LocalStorageへの格納
   * @param key キー名
   */
  getLocalStorage(key: string): any {
    const jsonVal = localStorage.getItem(key);
    const val = jsonVal ? JSON.parse(jsonVal) : undefined;
    return val;
  },

  /**
   * LocalStorageから削除
   * @param key キー名
   */
  removeLocalStorage(key: string) {
    localStorage.removeItem(key);
  }
};

export default WebStorage;
