






























import Vue from 'vue';
import HighPricePd from '@/components/high-price-pd.vue';
import SyuttyouKaitoriItemDetail from './syuttyou-kaitori-item-detail.vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-item',
  components: {
    'high-price-pd': HighPricePd,
    'syuttyou-kaitori-item-detail': SyuttyouKaitoriItemDetail
  },
});

