












































































import Vue from 'vue';
import { reactive, toRefs, PropType, computed } from '@vue/composition-api';
import { ProductInfo } from '@/shims-db';
import { noimage, roundDownSellPrice } from '@/logic/utils';
import { SUGGEST_CATEGORY_LIST } from '../../constants/suggest-categories';

export default Vue.extend({
  name: 'product',
  components: {},
  props: {
    product: {
      type: Object as PropType<ProductInfo>,
      required: true
    },
    tag: {
      type: String,
      required: true
    },
    isPrice: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: (props, context) => {
    const { sellEstimate } = context.root.$store;
    const state = reactive({
      stateProduct: props.product as ProductInfo
    });

    /**
     * 買取アップ表示カテゴリーかの判断
     */
    const isDisplaySuggest = computed(() => {
      return SUGGEST_CATEGORY_LIST.has(state.stateProduct.category1);
    });

    /**
     * 見積り追加処理
     * @param goodsCode 商品コード
     * @param tradeinS 買取り価格
     */
    const add = (goodsCode: string, tradeinS: string) => {
      context.emit('add', goodsCode, tradeinS);
    };

    /**
     * 見積り追加処理
     * @param goodsCode 商品コード
     */
    const remove = (goodsCode: string) => {
      sellEstimate.remove(goodsCode);
    };

    return {
      ...toRefs(state),
      noimage,
      roundDownSellPrice,
      isDisplaySuggest,
      add,
      remove
    };
  }
});
