import searchStore from '@/store/search';
import categoryStore from '@/store/category';
import sellEstimateStore from '@/store/sell-estimate';
import sellConditionStore from '@/store/sell-condition';
import loadStore from '@/store/load';
import headerStore from '@/store/header';
import errorStore from '@/store/error';

export default function globalStore() {
  return {
    searcher: searchStore(),
    category: categoryStore(),
    sellEstimate: sellEstimateStore(),
    sellCondition: sellConditionStore(),
    loader: loadStore(),
    header: headerStore(),
    errorStore: errorStore()
  };
}

export type GlobalStore = ReturnType<typeof globalStore>;
