






















































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import PhoneNumberPanel from '@/components/phone-number-panel.vue';

export default Vue.extend({
  name: 'telephone-application',
  components: {
    'phone-number-panel': PhoneNumberPanel
  },
  props: {
    isTop: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: () => {
    const state = reactive({
      baseURL: process.env.VUE_APP_BASE_URL
    });

    return {
      ...toRefs(state)
    };
  }
});
