// 査定商品一覧のソート順
export const SORT_LIST = [
  {
    text: '一致度順',
    value: 'keyword'
  },
  {
    text: '新しい順',
    value: 'add_date'
  },
  {
    text: '価格が高い順',
    value: 'price_desc'
  },
  {
    text: '価格が低い順',
    value: 'price_asc'
  },
  {
    text: 'メーカー順',
    value: 'maker'
  },
  {
    text: '商品種類順',
    value: 'category1'
  }
];
