






























































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import ProductInfo from '@/components/sell/product-info.vue';
import ProductSeries from '@/components/sell/product-series.vue';
import HighPricePoint from '@/components/sell/high-price-point.vue';
import PurchaseFlow2 from '@/components/sell/purchase-flow2.vue';
import ThreeSellingWays from '@/components/three-selling-ways.vue';
import FourReasons from '@/components/four-reasons.vue';
import SellingStore from '@/components/selling-store.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import NotFound from '@/components/common/not-found.vue';
import { SellProductDetail } from '@/types/sell-product-detail';
import SellService from '@/logic/sell.service';
import RobotsService from '@/logic/robots.service';
import KeywordsService from '@/logic/keywords.service';
import DetailPageFloatingCta from '@/components/sell/detail-page-floating-cta.vue';
import PromotionBanner from '@/components/page/common/promotion-banner.vue';
import TelephoneApplication from '@/components/telephone-application.vue';

export default Vue.extend({
  name: 'sell-detail-page',
  components: {
    breadcrumbs: Breadcrumbs,
    'product-info': ProductInfo,
    'product-series': ProductSeries,
    'high-price-point': HighPricePoint,
    'purchase-flow2': PurchaseFlow2,
    'three-selling-ways': ThreeSellingWays,
    'four-reasons': FourReasons,
    'selling-store': SellingStore,
    'section-loading': SectionLoading,
    'detail-page-floating-cta': DetailPageFloatingCta,
    'promotion-banner': PromotionBanner,
    'telephone-application': TelephoneApplication,
    'not-found': NotFound
  },
  setup(props, context) {
    const state = reactive({
      netshopUrl: process.env.VUE_APP_NET_SHOP_URL,
      janCode: '',
      usedQuotesCode: '',
      breadcrumbsList: [
        {
          no: 1,
          linkUrl: '/',
          path: 'カメラのキタムラ ネット中古'
        }
      ] as Array<{
        no: number;
        path: string;
        linkUrl?: string | undefined;
      }>,
      parentCateNm: '',
      product: {} as SellProductDetail,
      pageTitle: '',
      // ロード状態
      loaded: {
        product: false
      }
    });

    // メタ情報の設定
    const setMeta = () => {
      // title
      state.pageTitle = `${state.product.makerName} ${state.product.itemName} ${state.parentCateNm} の買取り価格`;
      document.title = state.pageTitle;

      // description
      document
        .querySelector<any>('meta[name="description"]')
        .setAttribute(
          'content',
          `${state.product.makerName} ${state.product.itemName} ${state.product.seriesName}の買取り価格を公開中！中古カメラ・中古レンズの買取・販売・下取りなら日本最大級のカメラ専門店カメラのキタムラへ。専門店だからできる相場で、安く販売・高く買取いたします！`
        );

      // robots
      RobotsService.set(`index`);

      // keywords
      KeywordsService.set(`${state.product.makerName},${state.product.itemName},${state.product.seriesName},買取,下取`);
    };

    // 買取相場詳細取得
    const fetchSellProduct = async () => {
      try {
        state.loaded.product = false;
        // 相場表コードの取得
        const productList = await SellService.searchProducByJanCode(state.janCode);
        state.usedQuotesCode = productList.length ? productList[0].goods_code : '';

        if (state.usedQuotesCode) {
          const result = await SellService.fetchSellProduct(state.janCode, state.usedQuotesCode);
          state.product = result;

          // パンくず設定:初期化
          state.breadcrumbsList = [
            {
              no: 1,
              linkUrl: '/',
              path: 'カメラのキタムラ ネット中古'
            }
          ];

          // パンくず設定:カテゴリー,メーカー
          if (state.product.breadcrumbs && state.product.breadcrumbs.length > 0) {
            // カテゴリー
            state.parentCateNm = state.product.breadcrumbs[0].path;
            state.breadcrumbsList.push({
              no: 2,
              linkUrl: `/ec/sell/item-list?category=${state.parentCateNm}`,
              path: `${state.parentCateNm}の買取価格相場`
            });

            // メーカー
            const makerNm = state.product.breadcrumbs[2]?.path || '';
            if (makerNm) {
              state.breadcrumbsList.push({
                no: 3,
                linkUrl: `/ec/sell/item-list?category=${state.parentCateNm}&keyword=${makerNm}`,
                path: `${makerNm} ${state.parentCateNm} の買取価格相場`
              });
            }
          }

          // パンくず設定:商品名
          state.breadcrumbsList.push({
            no: state.breadcrumbsList.length + 1,
            path: result.itemName
          });

          // メタ情報の設定
          setMeta();
        }
      } catch (error) {
        console.error(error);
        state.usedQuotesCode = '';
        state.product = {} as SellProductDetail;
      } finally {
        state.loaded.product = true;
      }
    };

    const init = () => {
      // ルートパスから「Janコード」取得
      state.janCode = context.root.$route.params.id;
      fetchSellProduct();
    };

    // 同一Vueへの遷移対応
    watch(
      () => context.root.$route,
      () => {
        init();
      }
    );

    onMounted(() => {
      init();
      // ブラウザの戻るボタン検知用（Vue系のものは、反応しないときがあるため）
      window.addEventListener('popstate', function (e) {
        init();
      });
    });

    return {
      ...toRefs(state)
    };
  }
});
