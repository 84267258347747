








import Vue from 'vue';
import { reactive, toRefs, onBeforeUnmount } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';

export default Vue.extend({
  name: 'not-found-page',
  components: {
    breadcrumbs: Breadcrumbs
  },
  props: {
    errorMessage: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const state = reactive({
      breadcrumbsList: [] as Array<{
        no: number;
        path: string;
        linkUrl?: string | undefined;
      }>
    });

    /** パンくずリスト作成 */
    state.breadcrumbsList = [
      {
        no: 1,
        path: 'ネット中古TOP',
        linkUrl: '/'
      },
      {
        no: 2,
        path: props.errorMessage as string
      }
    ];

    /** トップページに戻る */
    const autoNavigationTimerHandle = setTimeout(() => {
      context.root.$router.push({
        name: 'top-page'
      });
    }, 10000);

    /** ページ離脱時 */
    onBeforeUnmount(() => {
      clearTimeout(autoNavigationTimerHandle);
    });

    return {
      ...toRefs(state)
    };
  }
});
