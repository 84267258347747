







































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import BusinessHours from '@/components/business-hours.vue';

export default Vue.extend({
  name: 'phone-number-panel',
  components: {
    'business-hours': BusinessHours
  },
  props: {
    isDisplaySpImg: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup: () => {
    const state = reactive({});

    return {
      ...toRefs(state)
    };
  }
});
