







import Vue from 'vue';

export default Vue.extend({
  name: 'my-page',
  setup: (_, context) => {
    return {};
  }
});
