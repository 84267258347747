



































































































import Vue from 'vue';

export default Vue.extend({
  name: 'purchase-application-button',
  data() {
    return {
      dialog: false,
    }
  },
});
