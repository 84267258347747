

















































import Vue from 'vue';
import { reactive, toRefs, ref } from '@vue/composition-api';
import NavDrawerSubMenu from './nav-drawer-sub-menu.vue';
import { APPLIANCES_CATEGORY_LIST, CAMERA_CATEGORY_LIST } from '@/constants/categories';

const CAMERA_CATEGORY_MIN_SIZE = 4;
const APPLIANCES_CATEGORY_MIN_SIZE = 3;

export default Vue.extend({
  name: 'nav-drawer-menu',
  components: {
    'nav-drawer-sub-menu': NavDrawerSubMenu
  },
  setup: () => {
    const state = reactive({
      selectSubMenu: '',
      buy: [
        {
          plus: true,
          items: [
            { text: '買いたい', type: 'title', linkType: 'internal', url: process.env.VUE_APP_NET_SHOP_URL },
            { text: 'カメラ', type: 'main' }
          ],
          itemsPlus: ref<{ [key: string]: string }[]>([])
        },
        {
          plus: true,
          items: [{ text: '家電', type: 'main' }],
          itemsPlus: ref<{ [key: string]: string }[]>([])
        }
      ],
      sell: [
        {
          plus: false,
          items: [
            {
              text: '売りたい',
              type: 'title',
              linkType: 'external',
              url: '/'
            },
            {
              text: '無料査定',
              type: 'main',
              linkType: 'external',
              url: '/#quick-assessment-link'
            },
            {
              text: '店舗で買取り',
              type: 'main',
              linkType: 'external',
              url: 'https://www.kitamura.jp/map/'
            },
            {
              text: '宅配で買取り',
              type: 'main',
              linkType: 'external',
              url: '/sell/direct/about.do#a06'
            },
            {
              text: '出張で買取り',
              type: 'main',
              linkType: 'external',
              url: '/ec/page/service/syuttyou-kaitori'
            }
          ]
        }
      ],
      service: [
        {
          plus: false,
          items: [
            { text: 'サービスメニュー', type: 'title' },
            {
              text: '買取り・下取り',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/special/sale-fair/page/sell/'
            },
            {
              text: 'なんでも下取り',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_shitadori_index.html'
            },
            {
              text: 'トクトク交換',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/ec/special/general/tokutokukoukan'
            },
            {
              text: 'お支払い方法',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_shiharai_index.html'
            },
            {
              text: '5年間保証',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_hoshou_index.html'
            },
            {
              text: '修理のご案内',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/files/syuuri/index.html'
            },
            {
              text: '送料・手数料',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_souryou_index.html'
            },
            {
              text: '返品・交換',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_henpin_index.html'
            },
            {
              text: '商品お届け・納期',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/sitemap/riyou_otodoke_index.html'
            }
          ]
        }
      ],
      group: [
        {
          plus: false,
          items: [
            { text: 'グループサイト', type: 'title' },
            {
              text: 'プリントサービス',
              type: 'main',
              linkType: 'external',
              url: 'http://www.kitamura-print.com'
            },
            {
              text: 'フォトブック',
              type: 'main',
              linkType: 'external',
              url: 'http://photobook.kitamura.jp/'
            },
            {
              text: 'アップル製品修理サービス',
              type: 'main',
              linkType: 'external',
              url: 'http://www.kitamura.jp/service/apple/'
            },
            {
              text: 'スタジオマリオ',
              type: 'main',
              linkType: 'external',
              url: 'http://www.studio-mario.jp'
            },
            {
              text: '中古時計専門店',
              type: 'main',
              linkType: 'external',
              url: 'https://shop.kitamura.jp/watch/sell/'
            },
            {
              text: '店舗検索',
              type: 'main',
              linkType: 'external',
              url: 'http://sss.kitamura.jp/'
            }
          ]
        }
      ],
      netShopUrl: process.env.VUE_APP_NET_SHOP_URL
    });

    // 買いたい＞カメラのサブ項目を設定
    const cameraSubItems = CAMERA_CATEGORY_LIST.map((category) => ({
      text: category.text,
      url: category.url,
      type: 'sub',
      linkType: category.linkType || 'internal'
    }));
    state.buy[0].items.push(...cameraSubItems.slice(0, CAMERA_CATEGORY_MIN_SIZE));
    state.buy[0].itemsPlus = cameraSubItems.slice(CAMERA_CATEGORY_MIN_SIZE);

    // 買いたい＞家電のサブ項目を設定
    const appliancesSubItems = APPLIANCES_CATEGORY_LIST.map((category) => ({
      text: category.text,
      url: category.url,
      type: 'sub',
      linkType: category.linkType || 'internal'
    }));
    state.buy[1].items.push(...appliancesSubItems.slice(0, APPLIANCES_CATEGORY_MIN_SIZE));
    state.buy[1].itemsPlus = appliancesSubItems.slice(APPLIANCES_CATEGORY_MIN_SIZE);

    return {
      ...toRefs(state)
    };
  }
});
