import { NewerCategory, UsedCategory, SellCategory } from '@/types/tsv-config';
import TsvConfigService from './tsv-config.service';

const NsSysConfigsService = {
  /**
   * 新品カテゴリーを取得する
   */
  async fetchNewerCategories(): Promise<NewerCategory[]> {
    const path = 'list_sys/category.tsv';
    const response = await TsvConfigService.getNsTsv(path);
    return response;
  },

  /**
   * 中古カテゴリーを取得する
   */
  async fetchUsedCategories(): Promise<UsedCategory[]> {
    const path = 'list_sys/chuko_category.tsv';
    const response = await TsvConfigService.getNsTsv(path);
    return response;
  },

  /**
   * 買取りカテゴリーを取得する
   */
  async fetchSellCategories(): Promise<SellCategory[]> {
    const path = 'list_sys/sell_category.tsv';
    const response = await TsvConfigService.getNsTsv(path);
    return response;
  }
};

export default NsSysConfigsService;
