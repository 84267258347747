




































































































































import Vue from 'vue';
import { reactive, toRefs, PropType, watch, computed } from '@vue/composition-api';
import SellService from '@/logic/sell.service';
// import { SELL_CONDITIONS } from '@/constants/sell-conditions';

export default Vue.extend({
  name: 'conditions',
  components: {},
  props: {
    currentConditions: {
      type: Object as PropType<{ [key: string]: string }>,
      required: false
    }
  },
  setup: (props, context) => {
    const state = reactive({
      // 初期条件：選択肢
      products: [{}],
      makerList: [{}],
      // トクトク対象表示制御
      tokutokuCount: 0,
      // 絞り込み条件：選択肢
      detailOptionList: {} as { [key: string]: Array<string | { text: string; value: string; count: number }> },
      // 条件：値
      condition: {
        category: '',
        maker: '',
        keyword: '',
        tokutoku: ''
        // s2: '',
        // s3: '',
        // s4: '',
        // s5: '',
        // s7: '',
        // n2c: '',
        // n3c: '',
        // n4c: '',
        // n5c: '',
        // n6c: '',
        // n1c: ''
      } as { [key: string]: string }
    });

    /**
     * 選択肢の取得
     */
    async function setConditionList() {
      const conditionCount: { [key: string]: number | { [key: string]: number } } = await SellService.searchProductListCount(state.condition);

      // 初期条件：選択肢の格納
      state.products = SellService.getCategory(conditionCount.category as { [key: string]: number });
      state.makerList = SellService.getMakerList(conditionCount.maker as { [key: string]: number });

      // トクトク対象の表示制御
      const tokutokuCount = conditionCount.price_guarantee_flg as { [key: string]: number };
      state.tokutokuCount = tokutokuCount['1'];

      // 絞り込み条件：選択肢の格納
      // SELL_CONDITIONS.forEach((cond) => {
      //   const sortCount = Boolean(cond.displayType === 'select' && cond.paramKey !== 'n2c' && cond.paramKey !== 'n5c' && cond.paramKey !== 'n6c');
      //   state.detailOptionList[cond.paramKey] = SellService.getSelectOptionList(conditionCount[cond.paramKey] as { [key: string]: number }, sortCount);
      // });
    }

    /**
     * 条件の変更
     */
    const changeCondition = () => {
      context.emit('change-condition', SellService.deleteEmptyConditionParam(state.condition));
    };

    /**
     * 条件のリセット
     */
    const reset = () => {
      context.emit('change-condition', {});
    };

    /**
     * SP用 ドロワーを閉じる
     */
    const closeDrawer = () => {
      context.emit('close-drawer');
    };

    // URLパラメーターの反映
    watch(
      () => [props.currentConditions],
      () => {
        state.condition = props.currentConditions as { [key: string]: string };
        setConditionList();
      },
      { deep: true }
    );

    /**
     * リセット／絞り込みボタンの表示制御
     */
    const activeResetBtn = computed(() => {
      const notEmptyCond = Object.entries(state.condition).find(([key, val]) => val);
      return notEmptyCond;
    });

    return {
      ...toRefs(state),
      // SELL_CONDITIONS,
      changeCondition,
      reset,
      closeDrawer,
      activeResetBtn
    };
  }
});
