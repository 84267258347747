

















import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';

export default Vue.extend({
  name: 'error-dialog',
  setup: (_, context) => {
    const { errorStore } = context.root.$store;

    const state = reactive({
      errorDialog: false,
      errorMessage: ''
    });

    /** ダイアログを閉じる */
    const close = () => {
      state.errorDialog = false;
      errorStore.clear();
      errorStore.close();
    };

    /** エラーメッセージが更新されたダイアログを表示する */
    watch(
      () => errorStore.errorMessage,
      (errorMessage) => {
        if (errorMessage) {
          state.errorMessage = errorMessage;
          state.errorDialog = true;
        }
      }
    );

    return {
      ...toRefs(state),
      close
    };
  }
});
