






import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-news',
});

