var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'search-menu-fixed': _vm.$vuetify.breakpoint.smAndDown && _vm.searcher.fixed
  },attrs:{"id":"search-menu"}},[_c('div',{staticClass:"header-search-wrapper"},[_c('div',{staticClass:"header-search-area",class:{
        'header-search-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'header-search-area-narrow align-center': _vm.$vuetify.breakpoint.smAndDown
      }},[(_vm.$vuetify.breakpoint.smAndDown && _vm.searcher.fixed)?_c('v-btn',{staticClass:"search-menu-fixed-close-btn",attrs:{"text":"","icon":""},on:{"click":function($event){return _vm.closeFixedSearch()}}},[_c('v-icon',[_vm._v("fas fa-arrow-left")])],1):_vm._e(),_c('div',{staticClass:"search-newold search-box-area-sm",class:{
          'pr-3': _vm.$vuetify.breakpoint.mdAndUp,
          'pr-2': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('v-select',{staticClass:"form-text",attrs:{"id":"search-newold","items":_vm.newOldList,"outlined":"","dense":""},model:{value:(_vm.selectNewOld),callback:function ($$v) {_vm.selectNewOld=$$v},expression:"selectNewOld"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"search-category search-box-area-md pr-3"},[_c('v-select',{staticClass:"form-text",attrs:{"id":"search-category","items":_vm.categoryList,"outlined":"","dense":""},model:{value:(_vm.selectCategory),callback:function ($$v) {_vm.selectCategory=$$v},expression:"selectCategory"}})],1):_vm._e(),_c('div',{staticClass:"search-keyword search-box-area-lg",class:{
          'pr-3': _vm.$vuetify.breakpoint.mdAndUp,
          'pr-0': _vm.$vuetify.breakpoint.smAndDown
        }},[_c('div',[_c('v-combobox',{ref:"keywordSearchCombobox",staticClass:"search-box form-text",attrs:{"id":"search-keyword","items":_vm.suggestItems,"loading":_vm.isLoading,"search-input":_vm.search,"hide-no-data":"","hide-selected":"","item-text":"keyword","item-value":"name","placeholder":"キーワードで探す","append-icon":"","append-outer-icon":"far fa-search","clearable":"","no-filter":"","menu-props":{
              allowOverflow: true,
              maxHeight: _vm.$vuetify.breakpoint.smAndDown ? 'calc(100vh - 214px)' : 600,
              closeOnClick: true,
              closeOnContentClick: true,
              minWidth: _vm.$vuetify.breakpoint.xsOnly ? '90vw' : null,
              offsetY: true,
              contentClass: _vm.$vuetify.breakpoint.smAndDown ? 'search-box-menu-fixed' : ''
            },"outlined":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click:append-outer":function($event){return _vm.toProductList()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.keydownWrapper($event.keyCode)},"focus":function($event){return _vm.searcher.changeFixed(true)}},scopedSlots:_vm._u([{key:"item",fn:function(data){return [(_vm.isObjectType(data.item))?[_c('v-list-item-content',{on:{"click":function($event){return _vm.selectSuggest(data.item.field, data.item.name, data.item.itemId)}}},[(data.item.field !== 'category')?_c('v-list-item-title',{staticClass:"show-suggest-item show-suggest-item-title",domProps:{"textContent":_vm._s(data.item.name)}}):_c('v-list-item-title',{staticClass:"show-suggest-item-title"},[_c('span',{staticClass:"show-suggest-category"},[_vm._v(_vm._s(data.item.name))]),_vm._v(" から「 "),_c('span',{staticClass:"show-suggest-item"},[_vm._v(_vm._s(_vm.search))]),_vm._v(" 」で検索する("+_vm._s(data.item.count)+") ")])],1)]:[_c('v-list-item-content',{domProps:{"textContent":_vm._s(data.item)}},[_c('v-list-item-title',{staticClass:"show-suggest-item show-suggest-item-title",domProps:{"textContent":_vm._s(data.item)}})],1)]]}}])})],1)])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"header-menu-area-wide"},[_c('user-menu')],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }