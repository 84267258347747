
























































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import MyPageLayout from '../common/my-page-layout.vue';
import EstimateInfo from './estimate-info.vue';
import MoreItemsButton from '@/components/common/more-items-button.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import BackButton from '@/components/common/back-button.vue';
import { roundDownSellPrice } from '@/logic/utils';
import { SavedEstimate, SavedEstimateProduct } from '@/types/estimate-list';

export default Vue.extend({
  name: 'sell-list',
  components: {
    'my-page-layout': MyPageLayout,
    'estimate-info': EstimateInfo,
    'more-items-button': MoreItemsButton,
    'section-loading': SectionLoading,
    'back-button': BackButton
  },
  setup: (props, context) => {
    const { sellEstimate } = context.root.$store;
    const state = reactive({
      nsBaseUrl: process.env.VUE_APP_NET_SHOP_URL,
      // 画面タイトル
      title: '買取り見積り一覧',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: process.env.VUE_APP_NET_SHOP_URL + 'ec/mypage' },
        { path: '買取り見積り一覧', linkUrl: '' }
      ],
      // 一覧
      estimateList: [] as Array<SavedEstimate>,
      showEstimateList: [] as Array<SavedEstimate>,
      // 表示数
      showOrderLength: 10,
      // ローディング
      loaded: {
        estimateList: false
      }
    });

    // 買取り見積り一覧を取得
    const fetchEstimateList = async () => {
      state.loaded.estimateList = false;
      try {
        const result = await sellEstimate.savedEstimateList;
        // 登録日順でソート
        result.sort((a: SavedEstimate, b: SavedEstimate) => {
          if (a.date > b.date) return -1;
          if (a.date < b.date) return 1;
          return 0;
        });

        // 合計金額の算出
        result.map((estimate: SavedEstimate) => {
          let total = 0;
          estimate.productList.forEach((product: SavedEstimateProduct) => {
            total += roundDownSellPrice(product.price);
          });
          estimate.totalPrice = total;
        });

        state.estimateList = result;
        state.showEstimateList = result.slice(0, state.showOrderLength);
      } catch (error) {
        console.error(error);
        state.estimateList = [] as Array<SavedEstimate>;
      } finally {
        state.loaded.estimateList = true;
      }
    };

    onMounted(() => {
      fetchEstimateList();
    });

    // 次の一覧を取得する
    const showMore = () => {
      state.loaded.estimateList = false;
      state.showOrderLength += 10;
      const list = state.estimateList;
      state.showEstimateList = list.slice(0, state.showOrderLength);
      state.loaded.estimateList = true;
    };

    /**
     * 削除
     */
    const remove = (id: string) => {
      sellEstimate.removeSavedEstimate(id);
      fetchEstimateList();
    };

    return {
      ...toRefs(state),
      showMore,
      remove
    };
  }
});
