




















































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import { isModernBrowser } from '@/logic/utils';

export default Vue.extend({
  name: 'threeSellingWays',
  props: {
    // 買取り見積詳細
    sellDetail: {
      required: false,
      type: Boolean,
      default: false
    },
    isTop: {
      type: Boolean,
      required: false,
      default: false
    },
    seoTag: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  setup: (props, context) => {
    const state = reactive({
      targetStore: '#selling-store-link',
      targetStore2: 'https://www.kitamura.jp/used/list.html',
      targetDelivery: '#selling-delivery-link',
      targetVisit: '#selling-visit-link',
      model: null
    });

    const targetStore2 = () => {
      window.open('https://www.kitamura.jp/used/list.html', '_blank');
    };

    function scrollTo(targetHash: string) {
      if (props.sellDetail as boolean) {
        window.location.href = `/${targetHash}`;
      } else if (!isModernBrowser() && context.root.$vuetify.breakpoint.mdAndUp) {
        window.location.hash = '';
        window.location.hash = targetHash;
      } else {
        context.root.$vuetify.goTo(targetHash);
      }
    }

    return {
      ...toRefs(state),
      targetStore2,
      scrollTo
    };
  }
});
