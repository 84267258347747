import { CarouselBanner, HighPriceProduct, MovieNews, SpecialSaleNews } from '@/types/tsv-config';
import TsvConfigService from './tsv-config.service';

const TopConfigsService = {
  /**
   * カルーセルバナー設定を取得する
   * @param timeAdd 指定した時間だけ現在時刻に加える
   */
  async fetchCarouselBanners(timeAdd: number): Promise<CarouselBanner[]> {
    const localPath = `http://${window.location.host}/tsv/top/carousel.tsv`;
    const path = 'top/carousel.tsv';
    const response = await TsvConfigService.getTsv(path, {}, timeAdd, localPath);
    return response;
  },

  /**
   * 高価買取り商品（manual）を取得する
   */
  async fetchManualProduct(): Promise<HighPriceProduct[]> {
    const localPath = `http://${window.location.host}/tsv/top/chuko_top_buy_product_manual.tsv`;
    const path = 'top/chuko_top_buy_product_manual.tsv';
    const response = await TsvConfigService.getTsv(path, {}, 0, localPath, true);
    return response;
  },

  /**
   * 高価買取り商品（alt）を取得する
   */
  async fetchAltProduct(): Promise<HighPriceProduct[]> {
    const localPath = `http://${window.location.host}/tsv/top/chuko_top_buy_product_alt.tsv`;
    const path = 'top/chuko_top_buy_product_alt.tsv';
    const response = await TsvConfigService.getTsv(path, {}, 0, localPath, true);
    return response;
  },

  /**
   * 高価買取り商品（auto）を取得する
   */
  async fetchAutoProduct(): Promise<HighPriceProduct[]> {
    const localPath = `http://${window.location.host}/tsv/top/chuko_top_buy_product_auto.tsv`;
    const path = 'top/chuko_top_buy_product_auto.tsv';
    const response = await TsvConfigService.getTsv(path, {}, 0, localPath, true);
    return response;
  },

  /**
   * 新着用動画設定を取得する
   * @param timeAdd 指定した時間だけ現在時刻に加える
   */
  async fetchMovieNews(timeAdd: number): Promise<MovieNews[]> {
    const path = 'top/movie.tsv';
    const response = await TsvConfigService.getTsv(path, {}, timeAdd);
    return response;
  },

  /**
   * 特集・セール設定を取得する
   * @param timeAdd 指定した時間だけ現在時刻に加える
   */
  async fetchSpecialSaleNews(timeAdd: number): Promise<SpecialSaleNews[]> {
    const path = 'top/sale.tsv';
    const response = await TsvConfigService.getTsv(path, {}, timeAdd);
    return response;
  }
};

export default TopConfigsService;
