











































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import suggestService from '@/logic/suggest.service';
import { noimage, formatPrice, roundDownSellPrice, splitList } from '@/logic/utils';
import { ProductInfo } from '@/shims-db';
import TopConfigsService from '@/logic/tsv/top-configs.service';
import { HighPriceProduct } from '@/types/tsv-config';

const CATEGORY_LIST = ['交換レンズ', 'ミラーレス一眼', 'デジタル一眼レフ', 'フィルムカメラ'];

export default Vue.extend({
  name: 'highPricePd',
  components: {},
  setup: (_, context) => {
    const state = reactive({
      initTabKey: 'C002', //初期表示時のタブキー
      tagIndex: 2,
      selectedTabName: 'ミラーレス一眼',
      toggleExclusive: 1,
      manualProductList: [] as HighPriceProduct[],
      altProductList: [] as HighPriceProduct[],
      autoProductList: [] as HighPriceProduct[],
      productList: [] as ProductInfo[],
      baseURL: process.env.VUE_APP_BASE_URL
    });

    // manual一覧を取得
    const fetchManualProduct = async () => {
      try {
        const result = await TopConfigsService.fetchManualProduct();
        state.manualProductList = result;
      } catch (error) {
        console.error(error);
        state.manualProductList = [];
      }
    };

    // alt一覧を取得
    const fetchAltProduct = async () => {
      try {
        const result = await TopConfigsService.fetchAltProduct();
        state.altProductList = result;
      } catch (error) {
        console.error(error);
        state.altProductList = [];
      }
    };

    // auto一覧を取得
    const fetchAutoProduct = async () => {
      try {
        const result = await TopConfigsService.fetchAutoProduct();
        state.autoProductList = result;
      } catch (error) {
        console.error(error);
        state.autoProductList = [];
      }
    };

    // 現在のカテゴリーでの商品コードの取得
    const filterCodeList = (list: HighPriceProduct[]) => {
      return list.filter((product) => product.category === state.selectedTabName && product.code).map((product) => product.code);
    };

    // 表示商品を設定
    async function setProductList() {
      let codeList: Array<string> = [];

      // manual
      await fetchManualProduct();
      codeList = filterCodeList(state.manualProductList);

      // alt, auto
      if (!codeList.length) {
        await fetchAltProduct();
        await fetchAutoProduct();

        if (state.altProductList.length && state.autoProductList.length) {
          const replaceList = state.altProductList.map((altPro) => {
            const target = state.autoProductList.find((autoPro) => altPro.category === autoPro.category && altPro.odr === autoPro.odr);
            return target ? target : altPro;
          });
          codeList = filterCodeList(replaceList);
        } else if (!state.altProductList.length && state.autoProductList.length) {
          codeList = filterCodeList(state.autoProductList);
        } else {
          codeList = filterCodeList(state.altProductList);
        }
      }

      // 表示商品取得
      if (codeList.length) {
        // 10件以上はレスポンスされないため、5件区切りで取得
        const splitCode = splitList(codeList, 5);
        const responses = splitCode.map((code) => suggestService.getHighPriceProductList(code));
        state.productList = (await Promise.all(responses)).reduce((list, value) => list.concat(value), new Array<ProductInfo>());
      }
    }

    /** タブ位置を更新 */
    function updateIndex(index: number): void {
      setProductList();
      state.selectedTabName = CATEGORY_LIST[index - 1];
      if (context.root.$vuetify.breakpoint.mdAndUp) state.tagIndex = index;
    }

    /** アイコンの色を取得 */
    function getIconColor(index: number): string {
      if (state.tagIndex == index) return '#06a865';
      return '#222';
    }

    function getPrice(tradeInPrice: number): string {
      return formatPrice(roundDownSellPrice(tradeInPrice));
    }

    /** 初期化 */
    setProductList();

    return {
      CATEGORY_LIST,
      ...toRefs(state),
      setProductList,
      updateIndex,
      getIconColor,
      getPrice,
      noimage
    };
  }
});
