import { render, staticRenderFns } from "./pr-hosoku.vue?vue&type=template&id=3282f8fe&scoped=true&"
import script from "./pr-hosoku.vue?vue&type=script&lang=ts&"
export * from "./pr-hosoku.vue?vue&type=script&lang=ts&"
import style0 from "./pr-hosoku.vue?vue&type=style&index=0&id=3282f8fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3282f8fe",
  null
  
)

export default component.exports