






























































import Vue from 'vue';
import { reactive, toRefs, onMounted, ref, SetupContext } from '@vue/composition-api';
import Banner from '@/components/header/banner.vue';
import HearderTop from '@/components/header/header-top.vue';
import SearchMenu from '@/components/header/search-menu.vue';
import TabMenu from '@/components/header/tab-menu.vue';
// import NotificationUnspported from '@/components/header/notification-unsupported.vue'

export default Vue.extend({
  name: 'app-header',
  components: {
    banner: Banner,
    'search-menu': SearchMenu,
    'tab-menu': TabMenu,
    'hearder-top': HearderTop
    // 非対応ブラウザの警告表示は現状OFFとする
    // 'notification-unsupported': NotificationUnspported
  },
  setup: (_, context: SetupContext) => {
    const { header } = context.root.$store;

    const state = reactive({
      clipped: false,
      headerAreaWideFixed: ref<HTMLElement>(),
      headerAreaWideRel: ref<HTMLElement>(),
      headerAreaNarrowFixed: ref<HTMLElement>(),
      headerAreaNarrowRel: ref<HTMLElement>(),
      headerAreaNarrowSlide: ref<HTMLElement>(),
      headerAreaNarrowSlideFixed: false,
      headerAreaWelcomeHide: false
    });

    const onScroll = () => {
      if (state.headerAreaNarrowRel && state.headerAreaNarrowSlide && state.headerAreaNarrowFixed) {
        if (window.pageYOffset >= state.headerAreaNarrowRel.clientHeight) {
          state.headerAreaNarrowSlideFixed = true;
          const top = state.headerAreaNarrowFixed?.clientHeight ? state.headerAreaNarrowFixed?.clientHeight : 0;
          state.headerAreaNarrowSlide.style.top = top + 'px';
          const headerFixedHeight = state.headerAreaNarrowSlide.clientHeight + state.headerAreaNarrowFixed.clientHeight;
          state.headerAreaNarrowRel.style.marginTop = headerFixedHeight + 'px';
          header.setHeight(headerFixedHeight);
        } else {
          state.headerAreaNarrowSlideFixed = false;
          const headerFixedHeight = state.headerAreaNarrowFixed.clientHeight;
          state.headerAreaNarrowRel.style.marginTop = headerFixedHeight + 'px';
          header.setHeight(headerFixedHeight);
        }
      }
      if (window.pageYOffset > 5) {
        state.headerAreaWelcomeHide = true;
      } else {
        state.headerAreaWelcomeHide = false;
      }
    };

    onMounted(() => {
      if (state.headerAreaWideRel) {
        state.headerAreaWideRel.style.marginTop = (state.headerAreaWideFixed?.clientHeight ? state.headerAreaWideFixed?.clientHeight : 0) + 'px';
      }
      if (state.headerAreaNarrowRel) {
        state.headerAreaNarrowRel.style.marginTop = (state.headerAreaNarrowFixed?.clientHeight ? state.headerAreaNarrowFixed?.clientHeight : 0) + 'px';
      }

      // 要素の変更検出
      const mutationObserver = new MutationObserver(() => {
        if (state.headerAreaWideRel) {
          state.headerAreaWideRel.style.marginTop = (state.headerAreaWideFixed?.clientHeight ? state.headerAreaWideFixed?.clientHeight : 0) + 'px';
        }
        if (state.headerAreaNarrowRel) {
          state.headerAreaNarrowRel.style.marginTop = (state.headerAreaNarrowFixed?.clientHeight ? state.headerAreaNarrowFixed?.clientHeight : 0) + 'px';
        }
      });
      const target = document.querySelector('.header-area-fixed');
      if (target) {
        mutationObserver.observe(target, {
          childList: true
        });
      }
    });

    return {
      onScroll,
      ...toRefs(state)
    };
  }
});
