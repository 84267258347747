







































































































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import { roundDownSellPrice } from '@/logic/utils';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Step from '@/components/sell/step.vue';
import TelephoneApplication from '@/components/telephone-application.vue';
import SellService from '@/logic/sell.service';
import { packageKitEstimate } from '@/types/estimate-list';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

const PURCHASE_TYPE = {
  STORE: 1,
  HOME_DELIVERY: 2,
  OFFICIAL_TRIP: 3
};

export default Vue.extend({
  name: 'sell-select-page',
  components: {
    breadcrumbs: Breadcrumbs,
    step: Step,
    'telephone-application': TelephoneApplication
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | 買取り方法選択'
    };
  },
  setup: (_, context) => {
    const { loader, sellEstimate } = context.root.$store;
    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 見積りもり選択
      selectedPurchase: PURCHASE_TYPE.STORE,
      // 見積り
      estimate: {} as packageKitEstimate,
      // スクロール
      target: '#content-title',
      options: {
        duration: 100,
        offset: 170,
        easing: 'linear'
      }
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '無料オンライン見積り',
          linkUrl: '/ec/sell?type=search'
        },
        {
          path: '買取り見積り一覧',
          linkUrl: '/ec/sell/item-price'
        },
        {
          path: '買取り方法選択',
          linkUrl: ''
        }
      ];

      // 見積り詳細を取得
      state.estimate = sellEstimate.packageKitEstimate;
    });

    /**
     * 見積り日のフォーマット
     */
    const formatEstimateDate = (date: string) => {
      return date.includes(':') ? dayjs(date).format('YYYY年MM月DD日 HH時mm分') : dayjs(date).format('YYYY年MM月DD日');
    };

    /**
     * 梱包キットを申込む押下処理
     */
    const offerPackagingKit = async () => {
      loader.routeUnset();
      await SellService.offerPackagingKit(state.estimate.productList);
      window.location.href = '/sell/direct/init.do?serviceId=50';
      sellEstimate.clearPackageKitEstimate();
    };

    return {
      PURCHASE_TYPE,
      ...toRefs(state),
      dayjs,
      roundDownSellPrice,
      formatEstimateDate,
      offerPackagingKit,
      dialog: false,
    };
  }
});
