import { reactive } from '@vue/composition-api';
import WebStorage from '@/logic/web-storage';
import { ProductInfo } from '@/shims-db';
import { SavedEstimate, packageKitEstimate } from '@/types/estimate-list';
import dayjs from 'dayjs';

export default function sellEstimateStore() {
  const KEY = 'estimateList';
  const SAVED_KEY = 'savedEstimateList';
  const PACKAGE_KIT_KEY = 'packageKitEstimate';

  const state = reactive({
    estimateList: [] as Array<ProductInfo & { addDate?: string }>,
    savedEstimateList: [] as Array<SavedEstimate>,
    packageKitEstimate: {} as packageKitEstimate
  });

  return {
    /**
     * 未保存（検索結果追加買取り見積り商品一覧
     */
    get estimateList(): Array<ProductInfo> {
      state.estimateList = WebStorage.getLocalStorage(KEY) || [];
      if (state.estimateList.length) {
        // 追加日時より「1h」経過商品は、削除する
        state.estimateList.concat().forEach((product: ProductInfo & { addDate?: string }) => {
          const addDate = dayjs(product.addDate);
          const today = dayjs();
          if (!product.addDate || +today.diff(addDate, 'minute') >= 60) this.remove(product.goods_code);
        });
        state.estimateList = WebStorage.getLocalStorage(KEY);
      }
      return state.estimateList;
    },

    add(product: ProductInfo) {
      this.estimateList;
      state.estimateList.push({
        ...product,
        addDate: dayjs().toString()
      });
      WebStorage.setLocalStorage(KEY, state.estimateList);
    },

    remove(goodsCode: string) {
      const targetIndex = state.estimateList.findIndex((product) => product.goods_code === goodsCode);
      if (targetIndex > -1) {
        state.estimateList.splice(targetIndex, 1);
        WebStorage.setLocalStorage(KEY, state.estimateList);
      }
    },

    clear() {
      WebStorage.removeLocalStorage(KEY);
      state.estimateList = [];
    },

    /**
     * 保存した買取り見積り一覧
     */
    get savedEstimateList(): Array<SavedEstimate> {
      state.savedEstimateList = WebStorage.getLocalStorage(SAVED_KEY) || [];
      if (state.savedEstimateList.length) {
        // 有効期限より「3日」経過したものは、削除する
        state.savedEstimateList.concat().forEach((estimate: SavedEstimate) => {
          const expirationDate = dayjs(estimate.expirationDate);
          const today = dayjs();
          if (+today.diff(expirationDate, 'day') >= 3) this.removeSavedEstimate(estimate.id);
        });
        state.savedEstimateList = WebStorage.getLocalStorage(SAVED_KEY);
      }
      return state.savedEstimateList;
    },

    addSavedEstimate(estimate: SavedEstimate) {
      this.savedEstimateList;
      state.savedEstimateList.push(estimate);
      WebStorage.setLocalStorage(SAVED_KEY, state.savedEstimateList);
    },

    findSavedEstimateById(id: string): SavedEstimate | {} {
      this.savedEstimateList;
      const target = state.savedEstimateList.find((estimate) => estimate.id === id);
      return target || {};
    },

    removeSavedEstimate(id: string) {
      const targetIndex = state.savedEstimateList.findIndex((estimate) => estimate.id === id);
      if (targetIndex > -1) {
        state.savedEstimateList.splice(targetIndex, 1);
        WebStorage.setLocalStorage(SAVED_KEY, state.savedEstimateList);
      }
    },

    /**
     * 梱包キット申込み連携
     */
    get packageKitEstimate(): packageKitEstimate {
      const storagePackageKitEstimate = WebStorage.getSessionStorage(PACKAGE_KIT_KEY);
      state.packageKitEstimate = storagePackageKitEstimate || {};
      return state.packageKitEstimate;
    },

    addPackageKitEstimate(estimate: packageKitEstimate) {
      state.packageKitEstimate = estimate;
      WebStorage.setSessionStorage(PACKAGE_KIT_KEY, state.packageKitEstimate);
    },

    clearPackageKitEstimate() {
      WebStorage.removeSessionStorage(PACKAGE_KIT_KEY);
      state.packageKitEstimate = {} as packageKitEstimate;
    }
  };
}

export type SellEstimateStore = ReturnType<typeof sellEstimateStore>;
