







































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'purchase-flow2',
  components: {},
  setup: () => {
    const state = reactive({
      model: null
    });

    return {
      ...toRefs(state),
      tab: null,
      items: [
        '店頭買取', '直送買取', '出張買取',
      ],
      kaitoriSteps: [
        {
          id: 1,
          name: 'tenpo',
          children: [
            { id: 1, title: '店舗を検索', text: '｢店舗検索｣から買取り可能な店舗を検索ください', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_1-1.png', },
            { id: 2, title: 'ご来店', text: '買取りをご希望される商品と、ご本人確認書類を店舗までご持参ください', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_1-2.png', },
            { id: 3, title: '査定／買取り', text: '査定額を提示し、ご納得いただけましたら、その場で現金買取りいたします', text2: '※査定額によっては変わる場合がございます', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_1-3.png', },
          ],
        },
        {
          id: 2,
          name: 'takuhai',
          children: [
            { id: 1, title: '梱包キット申込み', text: '｢梱包キット申込みフォーム｣から、梱包キットを申込みます', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_2-1.png', },
            { id: 2, title: '梱包して発送', text: '必要書類と商品を梱包キットに入れて発送ください', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_2-2.png', },
            { id: 3, title: '査定／買取り', text: '後日、査定額をご連絡いたします\n査定額にご納得いただけましたら、お客さまの口座へお振込みいたします', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_2-3.png', },
          ],
        },
        {
          id: 3,
          name: 'syuttyo',
          children: [
            { id: 1, title: '出張買取のご予約', text: '電話、LINE、Webのいずれかからお申込みください\nオペレーターとご相談のうえ、訪問日を決めさせていただきます', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_3-1.png', },
            { id: 2, title: 'お伺い', text: 'お客さまのご自宅へお伺いし、査定をいたします', text2: '※法人買取の場合のみ、職場へお伺いし査定をいたします', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_3-2.png', },
            { id: 3, title: '査定／買取り', text: '査定額を提示し、ご納得いただけましたら、その場で現金買取りいたします', text2: '※査定額によっては変わる場合がございます', img: 'https://www.net-chuko.com/ec/images2/pages/sell/sell-detail-page/kaitori-step_3-3.png', },
          ],
        }
      ]
    };
  }
}
);
