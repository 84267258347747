




















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'back-to-top',
  setup: () => {
    const state = reactive({
      target: '#page-top',
      offset: {
        duration: 100,
        offset: 0,
        easing: 'linear'
      }
    });

    return {
      ...toRefs(state)
    };
  }
});
