






































































































































































































































































































































































































































































import Vue from 'vue';
import HoujinKaitoriFloatingCta from './houjin-kaitori-floating-cta.vue';
import SyuttyouKaitoriItemDetail from '../syuttyou-kaitori/syuttyou-kaitori-item-detail.vue';

export default Vue.extend({
  name: 'houjin-kaitori-top',
  components: {
    'houjin-kaitori-floating-cta': HoujinKaitoriFloatingCta,
    'syuttyou-kaitori-item-detail': SyuttyouKaitoriItemDetail,
  },
  data() {
    return {
      dialog: false,
    };
  },
});
