





















































import Vue from 'vue';
import dayjs from 'dayjs';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { News } from '@/types/news';
import { formatDate } from '@/logic/utils';
import NewsService from '@/logic/news.service';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

type NewsListItem = {
  month: number;
  list: Array<News>;
};

export default Vue.extend({
  name: 'news-list-page',
  components: {
    breadcrumbs: Breadcrumbs,
    'section-loading': SectionLoading
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | ニュース一覧'
    };
  },
  setup: (_, context) => {
    const today = dayjs(new Date());
    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 比較日（クエリパラメーター「at」で指定可能、それ以外は本日）
      comparisonDate: today,
      // 月毎のお知らせ一覧
      monthlyNewsList: [] as Array<NewsListItem>,
      // 表示できるお知らせ有無
      noData: false,
      // ロード状態
      loaded: {
        monthlyNewsList: true
      }
    });

    /**
     * news一覧をリセット
     */
    const resetNewsList = () => {
      state.monthlyNewsList = [];
      const startMonth = state.comparisonDate.month() + 1;
      for (let index = startMonth; index > 0; index--) {
        state.monthlyNewsList.push({
          month: index,
          list: []
        });
      }
    };

    /**
     * news一覧取得
     */
    const searchNewsList = async () => {
      state.loaded.monthlyNewsList = true;
      resetNewsList();
      try {
        const year = state.comparisonDate.year();
        const newsResult = await NewsService.searchNews(false, {
          from: `${year}01`,
          to: `${year}12`,
          newsType: []
        });

        // 未来日付は、非表示とする（クエリパラメーター指定時は、その日までの表示）
        // ※API側から指定年以外もレスポンスされるため。除外
        const filterNewsResult = newsResult.filter((news) => {
          const newsDate = dayjs(news.newsDate);
          return state.comparisonDate.diff(newsDate, 'day') >= 0 && state.comparisonDate.year() === newsDate.year();
        });

        if (filterNewsResult.length > 0) {
          // 各月ごとのデータに整形
          filterNewsResult.forEach((news) => {
            const resultNewsMonth = dayjs(news.newsDate).month() + 1;
            const index = state.monthlyNewsList.findIndex((stateNews) => stateNews.month === resultNewsMonth);
            if (index >= 0) state.monthlyNewsList[index].list.push(news);
          });
        } else {
          throw 'filterNewsResult.length 0';
        }
      } catch (error) {
        console.error(error);
        state.noData = true;
        state.monthlyNewsList = [] as Array<NewsListItem>;
      } finally {
        state.loaded.monthlyNewsList = false;
      }
    };

    const init = async () => {
      const params = context.root.$route.params;
      const query = context.root.$route.query;

      if (query.at) {
        // クエリパラメーターでの日付指定
        const at = query.at as string;
        state.comparisonDate = dayjs(at);
      } else if (params.year) {
        // 今年以外は、エラーページに遷移
        if (+params.year !== today.year()) {
          context.root.$router.push({ name: 'not-found-page' });
        }
      }

      await searchNewsList();
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'ニュース一覧',
          linkUrl: ''
        }
      ];
    };

    onMounted(() => {
      init();
      // ブラウザの戻るボタン検知用（Vue系のものは、反応しないときがあるため）
      window.addEventListener('popstate', function() {
        init();
      });
    });

    return {
      ...toRefs(state),
      formatDate
    };
  }
});
