




























































































































































































































































































import Vue from 'vue';
import PurchaseInfoFloatingCta from '@/components/page/service/purchase-info/purchase-info-floating-cta.vue';
import TherrReasons from '@/components/page/service/purchase-info/three-reasons.vue';
import PurchaseInfoItemDetail from '@/components/page/service/purchase-info/purchase-info-item-detail.vue';

export default Vue.extend({
  name: 'purchase-info-top',
  components: {
    'purchase-info-floating-cta': PurchaseInfoFloatingCta,
    'three-reasons': TherrReasons,
    'purchase-info-item-detail': PurchaseInfoItemDetail,
  },
  data() {
    return {
      dialog: false,
    };
  },
});
