













import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'step',
  props: {
    activeCount: {
      type: Number,
      required: true
    }
  },
  setup: () => {
    const state = reactive({
      stepList: ['機種選択', '見積り一覧', '買取り方法選択'] as Array<string>
    });
    return {
      ...toRefs(state)
    };
  }
});
