



































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import MyPageLayout from '../common/my-page-layout.vue';
import BackButton from '@/components/common/back-button.vue';
import EstimateInfo from '@/components/my-page/sell-detail/estimate-info.vue';
import EstimateProduct from '@/components/my-page/sell-detail/estimate-product.vue';
import EstimateTotalInfo from '@/components/my-page/sell-detail/estimate-total-info.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { roundDownSellPrice } from '@/logic/utils';
import { packageKitEstimate, SavedEstimate, SavedEstimateProduct } from '@/types/estimate-list';

export default Vue.extend({
  name: 'sell-detail',
  components: {
    'my-page-layout': MyPageLayout,
    'back-button': BackButton,
    'estimate-info': EstimateInfo,
    'estimate-product': EstimateProduct,
    'estimate-total-info': EstimateTotalInfo,
    'section-loading': SectionLoading
  },
  setup: (props, context) => {
    const { sellEstimate } = context.root.$store;
    const state = reactive({
      nsBaseUrl: process.env.VUE_APP_NET_SHOP_URL,
      // 画面タイトル
      title: '買取見積り詳細',
      // パンくずリスト
      breadcrumbs: [
        { path: 'TOP', linkUrl: '/' },
        { path: 'マイページTOP', linkUrl: process.env.VUE_APP_NET_SHOP_URL + 'ec/mypage' },
        { path: '買取見積り詳細', linkUrl: '' }
      ],
      // 買取見積り番号
      estimateId: '',
      // 買取見積り詳細
      estimateDetail: {} as SavedEstimate,
      // ロード状態
      loaded: {
        detail: false
      }
    });

    /**
     * 買取見積り詳細を取得
     * @param id 買取見積り番号
     */
    const fetchEstimateDetail = async () => {
      state.loaded.detail = false;
      try {
        const result = await sellEstimate.findSavedEstimateById(state.estimateId);
        state.estimateDetail = result;

        // 合計金額の算出
        let total = 0;
        state.estimateDetail.productList.forEach((product: SavedEstimateProduct) => {
          total += roundDownSellPrice(product.price);
        });
        state.estimateDetail.totalPrice = total;
      } catch (error) {
        console.error(error);
        state.estimateDetail = {} as SavedEstimate;
      } finally {
        state.loaded.detail = true;
      }
    };

    onMounted(() => {
      const params = context.root.$route.params;
      // estimateIdが無い場合は、NotFoundPageに遷移させる
      if (params.estimateId) {
        state.estimateId = `${params.estimateId}`;
        fetchEstimateDetail();
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    });

    /**
     * 削除
     */
    const remove = (id: string) => {
      sellEstimate.removeSavedEstimate(id);
      context.root.$router.push({ name: 'my-page-sell-list' });
    };

    /**
     * 買取り方法選択ページ遷移処理
     */
    const toSellSelectPage = () => {
      // クリア
      sellEstimate.clearPackageKitEstimate();
      // 梱包キット申込み連携用見積り保存
      sellEstimate.addPackageKitEstimate({
        date: state.estimateDetail.date,
        expirationDate: state.estimateDetail.expirationDate,
        productList: state.estimateDetail.productList
      } as packageKitEstimate);
      // 遷移
      context.root.$router.push({ name: 'sell-select-page' });
    };

    return {
      ...toRefs(state),
      remove,
      toSellSelectPage
    };
  }
});
