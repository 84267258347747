











































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import '@/styles/page.scss';
import HoujinKaitoriBanner from '@/components/page/service/houjin-kaitori/houjin-kaitori-banner.vue';
import TyokusouKaitoriFloatingCta from '@/components/page/service/tyokusou-kaitori/tyokusou-kaitori-floating-cta.vue';
import PrHosoku from '@/components/page/common/pr-hosoku.vue';
import PromotionBanner from '@/components/page/common/promotion-banner.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'service-tyokusou-kaitori',
  components: {
    breadcrumbs: Breadcrumbs,
    'houjin-kaitori-banner': HoujinKaitoriBanner,
    'tyokusou-kaitori-floating-cta': TyokusouKaitoriFloatingCta,
    'pr-hosoku': PrHosoku,
    'promotion-banner': PromotionBanner
  },
  setup: (_, context) => {
    document.title = '直送買取 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '★★★')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '直送買取',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
