


































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import SyuttyouKaitoriFloatingBtn from '@/components/page/service/syuttyou-kaitori/syuttyou-kaitori-floating-btn.vue';
import ByArea from '@/components/page/service/syuttyou-kaitori/by-area.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'syuttyou-kaitori-nara',
  components: {
    breadcrumbs: Breadcrumbs,
    'syuttyou-kaitori-floating-btn': SyuttyouKaitoriFloatingBtn,
    'by-area': ByArea
  },
  setup: (_, context) => {
    document.title = '奈良県の出張買取 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '奈良県の出張買取承ります。カメラ・交換レンズの買取りはカメラのキタムラにお任せ')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '出張買取',
          linkUrl: '/ec/page/service/syuttyou-kaitori'
        },
        {
          path: '奈良県',
          linkUrl: ''
        }
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
