






































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'feature-film-purchase',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (_, context) => {
    document.title = 'フィルムカメラ買取り強化 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'カメラのキタムラではフィルムカメラの査定価格を見直し、買取りを強化しています！')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: '特集',
          linkUrl: '/ec/page/guide/sitemap'
        },
        {
          path: 'フィルムカメラ買取り強化',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
