













import Vue from 'vue';

export default Vue.extend({
  name: 'back-button',
  props: {
    text: {
      type: String,
      default: 'トップに戻る'
    },
    to: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  }
});
