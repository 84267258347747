

























































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import QuickAssessment from '@/components/quick-assessment.vue';
import CheckEstimation from '@/components/sell/check-estimation.vue';
import SubContents from '@/components/sell/sub-contents.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

const QUOTE_TYPE = {
  COUNSELING_TO_OPERATOR: 1,
  SEARCH_KEYWORD: 2
};

export default Vue.extend({
  name: 'sell-top-page',
  components: {
    breadcrumbs: Breadcrumbs,
    'quick-assessment': QuickAssessment,
    'check-estimation': CheckEstimation,
    'sub-contents': SubContents
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | 無料オンライン見積り'
    };
  },
  setup: (_, context) => {
    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 見積りもり選択
      selectedQuote: QUOTE_TYPE.COUNSELING_TO_OPERATOR,
      // スクロール
      target: '#quote-select-title',
      options: {
        duration: 100,
        offset: 170,
        easing: 'linear'
      }
    });

    onMounted(() => {
      // クエリーパラメーターによる表示制御
      const query = context.root.$route.query;
      if (query.type && query.type === 'search') state.selectedQuote = QUOTE_TYPE.SEARCH_KEYWORD;

      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '無料オンライン見積り',
          linkUrl: ''
        }
      ];
    });

    return {
      ...toRefs(state),
      QUOTE_TYPE
    };
  }
});
