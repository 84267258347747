




























import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-voice',
  data() {
    return {
      syuttyouKaitoriVoices: [
        {
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/voice02.png',
          text: '主人が趣味で集めていたカメラがたくさんあって捨てるわけにもいかず、今回お願いいたしました'
        },
        {
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/voice03.png',
          text: '車で持ち運びもできず、どうしようか困っていました'
        },
        {
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/voice04.png',
          text: '子供が小さいので中々家を離れられないので助かりました'
        }
      ]
    };
  },
});
