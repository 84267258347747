













































































































































































































































import Vue from 'vue';
import { computed, reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'four-reasons',
  components: {},
  setup: () => {
    const state = reactive({
      model: null,
      panel: []
    });

    return {
      ...toRefs(state)
    };
  }
});
