


































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted, watch } from '@vue/composition-api';
import SellService from '@/logic/sell.service';
import { SELL_CONDITIONS } from '@/constants/sell-conditions';

export default Vue.extend({
  name: 'check-estimation',
  components: {},
  setup: (props, context) => {
    const state = reactive({
      isValidForm: false,
      // 絞り込み条件表示制御用
      isDisplayForDetailCondition: false,
      // 初期条件：選択肢
      products: [{}],
      makerList: [{}],
      // トクトク対象表示制御
      tokutokuCount: 0,
      // 絞り込み条件：選択肢
      detailOptionList: {} as { [key: string]: Array<string | { text: string; value: string; count: number }> },
      // 条件：値
      condition: {
        category: '',
        maker: '',
        keyword: '',
        tokutoku: '',
        s2: '',
        s3: '',
        s4: '',
        s5: '',
        s7: '',
        n2c: '',
        n3c: '',
        n4c: '',
        n5c: '',
        n6c: '',
        n1c: ''
      } as { [key: string]: string }
    });

    /**
     * 選択肢の取得
     */
    async function setConditionList() {
      const conditionCount: { [key: string]: number | { [key: string]: number } } = await SellService.searchProductListCount({
        category: state.condition.category
      });

      // 初期条件：選択肢の格納
      state.products = SellService.getCategory(conditionCount.category as { [key: string]: number });
      state.makerList = SellService.getMakerList(conditionCount.maker as { [key: string]: number });

      // トクトク対象の表示制御
      const tokutokuCount = conditionCount.price_guarantee_flg as { [key: string]: number };
      state.tokutokuCount = tokutokuCount['1'];

      // 絞り込み条件：選択肢の格納
      SELL_CONDITIONS.forEach((cond) => {
        const sortCount = Boolean(cond.displayType === 'select' && cond.paramKey !== 'n2c' && cond.paramKey !== 'n5c' && cond.paramKey !== 'n6c');
        state.detailOptionList[cond.paramKey] = SellService.getSelectOptionList(conditionCount[cond.paramKey] as { [key: string]: number }, sortCount);
      });
    }

    onMounted(() => {
      setConditionList();
    });

    /**
     * 条件のリセット
     */
    const reset = () => {
      state.condition = {
        category: '',
        maker: '',
        keyword: '',
        tokutoku: '',
        s2: '',
        s3: '',
        s4: '',
        s5: '',
        s7: '',
        n2c: '',
        n3c: '',
        n4c: '',
        n5c: '',
        n6c: '',
        n1c: ''
      };
    };

    /**
     * フォームバリデーション 必須入力
     */
    const requiredRule = (v: any) => !!v || '必須項目です';

    /**
     * バリデーション 査定検索結果ページへ遷移
     */
    function validateForm(isValid: boolean): void {
      if (isValid) {
        context.root.$router.push({
          name: 'sell-list-page',
          query: SellService.deleteEmptyConditionParam(state.condition)
        });
      }
    }

    // カテゴリーが変更時の絞り込み条件の制御
    watch(
      () => [state.condition.category],
      () => {
        setConditionList();
      }
    );

    return {
      ...toRefs(state),
      SELL_CONDITIONS,
      reset,
      requiredRule,
      validateForm
    };
  }
});
