






































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import suggestService from '../logic/suggest.service';

export default Vue.extend({
  name: 'sellingStore',
  components: {},
  setup: () => {
    const state = reactive({
      model: null,
      baseUrl: process.env.VUE_APP_API_COMMON_BASE_URL,
      disableMunicipality: true,
      prefectureList: [{ address1: '', kencode: 0, area: '' }],
      municipalityList: [{ address2: '', citycode: '' }],
      prefecture: 0,
      municipality: {},
      freeSearchWord: ''
    });
    async function setMenuList() {
      const menuList = await suggestService.getPrefecture(1);
      state.prefectureList = menuList;
    }
    function getPrefectureWord() {
      const prefecture = state.prefectureList.find((pre) => {
        if (pre.kencode == state.prefecture) return true;
      });
      const municipality = state.municipalityList.find((pre) => {
        if (pre.citycode == state.municipality) return true;
      });
      if (prefecture && municipality) {
        return prefecture.address1 + municipality.address2;
      } else return '';
    }
    async function setMunicipality() {
      state.municipalityList = await suggestService.getMunicipalityByCode(1, state.prefecture);
      state.disableMunicipality = false;
    }
    function changedLabel() {
      setMunicipality();
    }
    function greet() {
      console.log('hello-clicking', state.prefecture, state.municipality);
    }

    setMenuList();
    return {
      ...toRefs(state),
      setMenuList,
      changedLabel,
      setMunicipality,
      greet,
      getPrefectureWord
    };
  }
});
