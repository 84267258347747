




























































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'feature-tell-me-chuuban',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (_, context) => {
    document.title = 'おすすめフィルムカメラ-中判フィルムカメラ編- | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'レトロなデザインでカメラ女子にもおすすめの中判フィルムカメラについて、大宮ステラタウン中古買取センターの店長に取材してみました')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: '特集',
          linkUrl: '/ec/page/guide/sitemap'
        },
        {
          path: 'おすすめフィルムカメラ-中判フィルムカメラ編-',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
      dialog01: false,
      dialog02: false,
      dialog03: false,
      dialog04: false,
      dialog05: false,
    };
  }
});
