import { reactive } from '@vue/composition-api';

/**
 * エラー関連のStore
 */
export default function errorStore() {
  const state = reactive({
    /** エラーメッセージ */
    errorMessage: '',
    /** クローズ時のイベント発火用 */
    closeFlag: false
  });

  return {
    get errorMessage(): string {
      return state.errorMessage;
    },

    set errorMessage(message: string) {
      state.errorMessage = message;
      state.closeFlag = false;
    },

    get closeFlag(): boolean {
      return state.closeFlag;
    },

    clear(): void {
      state.errorMessage = '';
    },

    close(): void {
      state.closeFlag = true;
    }
  };
}

export type ErrorStore = ReturnType<typeof errorStore>;
