import { reactive } from '@vue/composition-api';
import WebStorage from '@/logic/web-storage';

export default function sellConditionStore() {
  const RECENT_CONDITION_KEY = 'recentCondition';
  const RECENT_SORT_KEY = 'recentSort';
  const RECENT_DISPLAY_COUNT_KEY = 'recentDisplayCount';
  const SAVED_CONDTION_KEY = 'savedCondtion';
  const SAVED_SORT_KEY = 'savedSort';
  const SAVED_DISPLAY_COUNT_KEY = 'savedDisplayCount';

  const state = reactive({
    recentCondition: {} as { [key: string]: string | Array<string> },
    recentSort: '',
    recentDisplayCount: 0,
    savedCondtion: {} as { [key: string]: string | Array<string> },
    savedSort: '',
    savedDisplayCount: 0
  });

  return {
    // 直近の条件（詳細画面からのページネーション用）
    get recentCondition(): { [key: string]: string | Array<string> } {
      const storageRecentCondition = WebStorage.getSessionStorage(RECENT_CONDITION_KEY);
      state.recentCondition = storageRecentCondition ? storageRecentCondition : {};
      return state.recentCondition;
    },

    get recentSort(): string {
      const storageRecentSort = WebStorage.getSessionStorage(RECENT_SORT_KEY);
      state.recentSort = storageRecentSort ? storageRecentSort : '';
      return state.recentSort;
    },

    get recentDisplayCount(): number {
      const storageRecentDisplayCount = WebStorage.getSessionStorage(RECENT_DISPLAY_COUNT_KEY);
      state.recentDisplayCount = storageRecentDisplayCount ? storageRecentDisplayCount : 0;
      return state.recentDisplayCount;
    },

    saveRecentCondtion(condtion: { [key: string]: string | Array<string> }, sort: string, displayCount: number) {
      state.recentCondition = condtion;
      WebStorage.setSessionStorage(RECENT_CONDITION_KEY, state.recentCondition);
      state.recentSort = sort;
      WebStorage.setSessionStorage(RECENT_SORT_KEY, state.recentSort);
      state.recentDisplayCount = displayCount;
      WebStorage.setSessionStorage(RECENT_DISPLAY_COUNT_KEY, state.recentDisplayCount);
    },

    // 条件の保存
    get savedCondtion(): { [key: string]: string | Array<string> } {
      const storageSavedCondtion = WebStorage.getLocalStorage(SAVED_CONDTION_KEY);
      state.savedCondtion = storageSavedCondtion ? storageSavedCondtion : {};
      return state.savedCondtion;
    },

    get savedSort(): string {
      const storageSavedSort = WebStorage.getLocalStorage(SAVED_SORT_KEY);
      state.savedSort = storageSavedSort ? storageSavedSort : '';
      return state.savedSort;
    },

    get savedDisplayCount(): number {
      const storageSavedDisplayCount = WebStorage.getLocalStorage(SAVED_DISPLAY_COUNT_KEY);
      state.savedDisplayCount = storageSavedDisplayCount ? storageSavedDisplayCount : 0;
      return state.savedDisplayCount;
    },

    save(condtion: { [key: string]: string | Array<string> }, sort: string, displayCount: number) {
      state.savedCondtion = condtion;
      WebStorage.setLocalStorage(SAVED_CONDTION_KEY, state.savedCondtion);
      state.savedSort = sort;
      WebStorage.setLocalStorage(SAVED_SORT_KEY, state.savedSort);
      state.savedDisplayCount = displayCount;
      WebStorage.setLocalStorage(SAVED_DISPLAY_COUNT_KEY, state.savedDisplayCount);
    },

    remove() {
      WebStorage.removeLocalStorage(SAVED_CONDTION_KEY);
      WebStorage.removeLocalStorage(SAVED_SORT_KEY);
      WebStorage.removeLocalStorage(SAVED_DISPLAY_COUNT_KEY);
      state.savedCondtion = {};
      state.savedSort = '';
      state.savedDisplayCount = 0;
    }
  };
}

export type SellConditionStore = ReturnType<typeof sellConditionStore>;
