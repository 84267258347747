var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"my-page-sell-detail"}},[_c('my-page-layout',{attrs:{"title":_vm.title,"breadcrumbs":_vm.breadcrumbs}},[(_vm.loaded.detail)?[_c('estimate-info',{attrs:{"info":{
          id: _vm.estimateDetail.id,
          date: _vm.estimateDetail.date,
          expirationDate: _vm.estimateDetail.expirationDate,
          totalPrice: _vm.estimateDetail.totalPrice,
          pruductCount: _vm.estimateDetail.productList.length
        }}}),_c('div',{class:{
          'mx-12': _vm.$vuetify.breakpoint.mdAndUp
        }},[_c('div',{staticClass:"estimate-product-list"},_vm._l((_vm.estimateDetail.productList),function(product){return _c('estimate-product',{key:product.janCode,attrs:{"product":product}})}),1),_c('estimate-total-info',{attrs:{"total-info":{
            totalPrice: _vm.estimateDetail.totalPrice,
            pruductCount: _vm.estimateDetail.productList.length
          }}})],1)]:(!_vm.loaded.detail)?_c('div',{staticClass:"loading-detail"},[_c('section-loading')],1):_vm._e(),_c('v-btn',{staticClass:"sell-apply-btn",attrs:{"dark":"","depressed":"","tile":""},on:{"click":function($event){return _vm.toSellSelectPage()}}},[_vm._v("買取りを申込む"),_c('v-icon',{staticClass:"sell-apply-btn-icon",attrs:{"small":""}},[_vm._v("fas fa-chevron-right")])],1),_c('div',{staticClass:"text-right",class:{
        'mx-12': _vm.$vuetify.breakpoint.mdAndUp
      }},[_c('v-btn',{staticClass:"delete-btn",attrs:{"text":""},on:{"click":function($event){return _vm.remove(_vm.estimateDetail.id)}}},[_vm._v(" 削除する "),_c('v-icon',{staticClass:"delete-btn-icon",attrs:{"small":""}},[_vm._v("far fa-times")])],1)],1)],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('back-button',{attrs:{"href":(_vm.nsBaseUrl + "ec/mypage")}},[_vm._v(" マイページトップへ戻る ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }