


















import Vue from 'vue';
export default Vue.extend({
  name: 'purchase-info-item-detail',
  data() {
    return {
      syuttyouKaitoriItems: [
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item01.jpg',
          text: '交換レンズ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item02.jpg',
          text: 'ミラーレス一眼／\nデジタル一眼レフ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item03.jpg',
          text: 'コンパクト\nデジタルカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item04.jpg',
          text: 'フィルムカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item05.jpg',
          text: 'ビデオカメラ'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item06.jpg',
          text: 'カメラ用品'
        },
        {
          url: 'https://www.net-chuko.com/ec/images2/pages/service/syuttyou-kaitori/by-area/item07.jpg',
          text: '腕時計'
        }
      ]
    };
  },

});

