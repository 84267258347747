import { reactive } from '@vue/composition-api';

export default function searchStore() {
  const state = reactive({
    searchWord: '' as string,
    fixed: false // 検索ボックスの固定
  });

  return {
    get searchWord(): string {
      return state.searchWord;
    },

    updateSearchWord(text: string) {
      state.searchWord = text;
    },

    get fixed(): boolean {
      return state.fixed;
    },

    changeFixed(val: boolean) {
      state.fixed = val;
    }
  };
}

export type SearchStore = ReturnType<typeof searchStore>;
