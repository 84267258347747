import { AxiosRequestConfig } from 'axios';
import dayjs from 'dayjs';

import ApiService from '../api.service';
import { isLocalHost } from '../utils';

/**
 * TSVを解析する
 * @param tsv タブ区切りの文字列
 */
const _parseTsv = (tsv: string, highPriceTsv = false): any => {
  let lines = tsv.split('\n');
  lines = lines.map((line) => line.trim()).filter((line) => line); // 空白行を削除

  let headers = (lines.shift() || '').split('\t');
  headers = headers.map((header) => header.trim()); // 余分な空白を削除

  return lines.map((line) => {
    const data = line.split('\t');
    return headers.reduce((obj: any, nextKey: string, index: number) => {
      if (highPriceTsv) {
        obj[nextKey.toLowerCase()] = data[index].trim();
      } else {
        obj[nextKey] = data[index].trim();
      }
      return obj;
    }, {});
  });
};

/**
 * 有効期限でフィルタする
 * @param list 一覧
 * @param timeAdd 指定した時間だけ現在時刻に加える
 */
const _filterValid = (list: Array<{ validFrom: string; validTo: string }>, timeAdd: number): Array<any> => {
  if (list && list.length > 0 && Object.prototype.hasOwnProperty.call(list[0], 'validFrom') && Object.prototype.hasOwnProperty.call(list[0], 'validTo')) {
    const now = dayjs().add(timeAdd, 'h');
    const filterList = list.filter((obj) => now.isBetween(obj.validFrom, obj.validTo));
    return filterList;
  }

  return list;
};

/**
 * 「index」でソートする
 * @param list 一覧
 */
const _indexSort = (list: Array<{ index: number }>): Array<any> => {
  if (list && list.length > 0 && Object.prototype.hasOwnProperty.call(list[0], 'index')) {
    const sortList = list.sort((a, b) => {
      if (+a.index < +b.index) return -1;
      if (+a.index > +b.index) return 1;
      return 0;
    });
    return sortList;
  }
  return list;
};

/**
 * 「odr」でソートする
 * @param list 一覧
 */
const _odrSort = (list: Array<{ odr: number }>): Array<any> => {
  if (list && list.length > 0 && Object.prototype.hasOwnProperty.call(list[0], 'odr')) {
    const sortList = list.sort((a, b) => {
      if (+a.odr < +b.odr) return -1;
      if (+a.odr > +b.odr) return 1;
      return 0;
    });
    return sortList;
  }
  return list;
};

const TsvConfigService = {
  /**
   * TSVファイルを取得する
   * @param path TSVファイルのあるパス
   * @param config リクエスト設定
   * @param timeAdd 指定した時間だけ現在時刻に加える
   */
  async getTsv(path: string, config: AxiosRequestConfig = {}, timeAdd = 0, localPath = '', highPriceTsv = false): Promise<any> {
    const url = isLocalHost() && localPath ? localPath : process.env.VUE_APP_TSV_BASE_URL + path;
    config.responseType = 'text';
    const response = await ApiService.get(url, config);

    let responseJson = _parseTsv(response, highPriceTsv);
    responseJson = _filterValid(responseJson, timeAdd);

    if (highPriceTsv) {
      responseJson = _odrSort(responseJson);
    } else {
      responseJson = _indexSort(responseJson);
    }

    return responseJson;
  },
  /**
   * ネットショップ側のTSVファイルを取得する
   * @param path TSVファイルのあるパス
   * @param config リクエスト設定
   * @param timeAdd 指定した時間だけ現在時刻に加える
   */
  async getNsTsv(path: string, config: AxiosRequestConfig = {}, timeAdd = 0, localPath = '', highPriceTsv = false): Promise<any> {
    const url = isLocalHost() && localPath ? localPath : process.env.VUE_APP_NET_SHOP_TSV_BASE_URL + path;
    config.responseType = 'text';
    const response = await ApiService.get(url, config);

    let responseJson = _parseTsv(response, highPriceTsv);
    responseJson = _filterValid(responseJson, timeAdd);

    if (highPriceTsv) {
      responseJson = _odrSort(responseJson);
    } else {
      responseJson = _indexSort(responseJson);
    }

    return responseJson;
  }
};

export default TsvConfigService;
