




















































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted, computed, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import Step from '@/components/sell/step.vue';
import Product from '@/components/sell/product.vue';
import SubContents from '@/components/sell/sub-contents.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import { hash, roundDownSellPrice } from '@/logic/utils';
import SellService from '@/logic/sell.service';
import { ProductInfo } from '@/shims-db';
import { packageKitEstimate, SavedEstimateProduct } from '@/types/estimate-list';
import dayjs from 'dayjs';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

const HASH_CODE = 'KameraNoKitamuraEstimateList';

export default Vue.extend({
  name: 'sell-price-page',
  components: {
    breadcrumbs: Breadcrumbs,
    step: Step,
    product: Product,
    'sub-contents': SubContents,
    'section-loading': SectionLoading
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | 買取り見積り一覧'
    };
  },
  setup: (_, context) => {
    const query = context.root.$route.query;
    const { sellEstimate, sellCondition, errorStore } = context.root.$store;
    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 今日
      today: dayjs(),
      // 見積り一覧
      productList: [] as Array<ProductInfo>,
      // ロード状態
      loaded: {
        productList: false
      },
      errorHash: false
    });

    /**
     * 追加済見積り商品の取得
     */
    const fetchEstimateList = async () => {
      try {
        state.loaded.productList = false;
        const storageList = sellEstimate.estimateList;
        const hashCode = await hash(HASH_CODE);
        if (storageList.some((product: ProductInfo) => product.hash !== hashCode)) {
          errorStore.errorMessage = '保管情報が変更になっています。';
          state.errorHash = true;
          sellEstimate.clear();
        } else {
          state.productList = storageList;
        }
      } catch (error) {
        console.error(error);
        state.productList = [];
      } finally {
        state.loaded.productList = true;
        // リロードやhistory.back時、再度追加されないようにreplace
        context.root.$router.replace({
          name: 'sell-price-page'
        });
      }
    };

    /**
     * 追加する商品をチェック
     * @param goodsCode 商品コード
     * @param tradeinS 最高買取り価格
     */
    const checkProduct = async (goodsCode: string, tradeinS: string) => {
      try {
        // 最新情報取得
        const results = await SellService.searchProductListByGoodsCode(goodsCode);

        // 複数の商品が取れる可能性があるため、goodsCodeが完全一致するものを追加する
        const targetProduct = results.find((product) => product.goods_code === goodsCode) as ProductInfo;

        if (targetProduct) {
          // TODO 価格チェック
          // マイページの持っている商品ページから遷移した際に価格が一致しない
          // if (targetProduct.tradein_s === tradeinS) {
          //   targetProduct.hash = await hash(HASH_CODE);
          //   sellEstimate.add(targetProduct);
          //   fetchEstimateList();
          // } else {
          //   errorStore.errorMessage = '買取り価格が変更になっています。';
          // }

          // 件数チェック
          if (sellEstimate.estimateList.length >= 10) {
            errorStore.errorMessage = '見積り追加に失敗しました。見積りは10点までとなります。削除して点数を減らすか保存してから、再操作をお願いいたします。';
          } else {
            targetProduct.hash = await hash(HASH_CODE);
            sellEstimate.add(targetProduct);
          }
        }

        fetchEstimateList();
      } catch (error) {
        console.error(error);
        state.productList = [];
      } finally {
        state.loaded.productList = true;
      }
    };

    onMounted(() => {
      // クエリーパラメーター有無による制御
      if (query.goodsCode && query.tradeinS) {
        checkProduct(`${query.goodsCode}`, `${query.tradeinS}`);
      } else {
        fetchEstimateList();
      }

      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'サービス案内',
          linkUrl: '/ec/page/guide/service-guide'
        },
        {
          path: '無料オンライン見積り',
          linkUrl: '/ec/sell?type=search'
        },
        {
          path: '買取り見積り一覧',
          linkUrl: ''
        }
      ];
    });

    /**
     * 合計金額
     */
    const totalPrice = computed(() => {
      let total = 0;
      state.productList.forEach((product) => {
        total += roundDownSellPrice(+product.tradein_s);
      });
      return total;
    });

    /**
     * エラーダイアログ閉じた際の処理(ハッシュエラー限定)
     */
    watch(
      () => errorStore.closeFlag,
      (newVal, oldVal) => {
        if (newVal !== oldVal && newVal && state.errorHash) {
          state.errorHash = false;
          context.root.$router.push({
            name: 'sell-top-page'
          });
        }
      }
    );

    /**
     * 一覧ページに戻る押下時処理
     */
    const backToSellList = () => {
      const param = SellService.deleteEmptyConditionParam(sellCondition.recentCondition);
      const storageSort = sellCondition.recentSort;
      const storageDisplayCount = sellCondition.recentDisplayCount;
      if (storageSort) param['sort'] = storageSort;
      if (storageDisplayCount) param['limit'] = storageDisplayCount;
      context.root.$router.push({
        name: 'sell-list-page',
        query: param
      });
    };

    /**
     * 保存用の商品リストを生成
     */
    const generateEstimateProductList = () => {
      const estimateProductList = [] as Array<SavedEstimateProduct>;
      state.productList.forEach((product) => {
        estimateProductList.push({
          janCode: product.jan_code,
          itemName: product.title,
          makerName: product.maker_name,
          imagePath: product.image,
          price: +product.tradein_s,
          goodsCode: product.goods_code
        });
      });
      return estimateProductList;
    };

    /**
     * この情報を保存しておく押下時処理
     */
    const savedEstimateList = () => {
      const generateId = `${state.today.format('YYYYMMDD')}-${('000000' + Math.floor(Math.random() * 1000000)).slice(-6)}`;

      sellEstimate.addSavedEstimate({
        id: generateId,
        date: state.today.format('YYYY/MM/DD HH:mm'),
        expirationDate: state.today.add(3, 'day').format('YYYY/MM/DD'),
        productList: generateEstimateProductList()
      });

      sellEstimate.clear();

      context.root.$router.push({ path: `/ec/mypage/sell/${generateId}` });
    };

    /**
     * 買取り方法選択ページ遷移処理
     */
    const toSellSelectPage = () => {
      // クリア
      sellEstimate.clearPackageKitEstimate();
      // 梱包キット申込み連携用見積り保存
      sellEstimate.addPackageKitEstimate({
        date: state.today.format('YYYY/MM/DD HH:mm'),
        expirationDate: state.today.add(3, 'day').format('YYYY/MM/DD'),
        productList: generateEstimateProductList()
      } as packageKitEstimate);
      // 遷移
      context.root.$router.push({ name: 'sell-select-page' });
    };

    return {
      ...toRefs(state),
      totalPrice,
      backToSellList,
      savedEstimateList,
      toSellSelectPage,
      dialog: false,
    };
  }
});
