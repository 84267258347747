





















































































































































































































































































































































































import Vue from 'vue';
import { reactive, ref, toRefs, onMounted, computed, watch } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import MakerSlider from '@/components/sell/maker-slider.vue';
import Conditions from '@/components/sell/conditions.vue';
import Product from '@/components/sell/product.vue';
import SubContents from '@/components/sell/sub-contents.vue';
import { SORT_LIST } from '@/constants/sort-list';
import { DISPLAY_COUNT_LIST } from '@/constants/display-conut-list';
// import { SELL_CONDITIONS } from '@/constants/sell-conditions';
import IncludeFIleService from '@/logic/include-file.service';
import SellService from '@/logic/sell.service';
import CanonicalService from '@/logic/canonical.service';
import { ProductInfo } from '@/shims-db';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'sell-list-page',
  components: {
    breadcrumbs: Breadcrumbs,
    'section-loading': SectionLoading,
    conditions: Conditions,
    'maker-slider': MakerSlider,
    product: Product,
    'sub-contents': SubContents
  },
  metaInfo: () => {
    return {
      title: '買取り価格相場|カメラのキタムラで高価買取り'
    };
  },
  setup: (_, context) => {
    const { header, sellCondition } = context.root.$store;
    const includeHtmlBanner = ref<string>('');
    const state = reactive({
      // SEO用のテキスト
      seoText: '',
      // 検索とURL書き換え判定用
      isChangedCond: false,
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 現在のカテゴリー
      currentCategory: '',
      // 検索条件
      currentConditions: {} as { [key: string]: string },
      // スマホ用の表示制御
      condtionDrawer: false,
      sortDrawer: false,
      // 並び替え
      currentSort: SORT_LIST[0].value,
      sort: SORT_LIST,
      // 表示件数
      currentDisplayCount: context.root.$vuetify.breakpoint.lgAndUp ? DISPLAY_COUNT_LIST[1].value : DISPLAY_COUNT_LIST[0].value,
      displayCountList: DISPLAY_COUNT_LIST,
      // 表示ページ
      page: 1,
      // 商品件数
      totalCount: -1,
      // 検索結果
      results: [] as Array<ProductInfo>,
      // ロード状態
      loaded: {
        results: false
      },
      // 検索条件部要素
      productSearchConditions: ref<HTMLElement>(),
      conditionsBtnWrap: ref<HTMLElement>(),
      productSearchConditionsFixed: false,
      // 検索条件保存系ボタン制御
      fixedConditionBtnArea: true,
      emptySaveCondition: true,
      spDisplaySaveBtn: true,
      // 保存条件系のツールチップ
      tooltipSave: false
    });

    /**
     * タイトル、パンくずなどのページ情報の設定
     */
    const setSeoInfo = () => {
      state.seoText = '';
      state.breadcrumbs = [
        {
          path: 'サイトトップ',
          linkUrl: '/'
        },
        {
          path: '無料オンライン見積り',
          linkUrl: '/ec/sell/'
        }
      ];
      CanonicalService.clear();

      const categoryNm = state.currentConditions.category || '';
      const makerNm = state.currentConditions.maker || '';
      const keyword = state.currentConditions.keyword || '';

      if (keyword) {
        state.seoText = `${keyword}`;
        state.breadcrumbs.push({
          path: `${keyword}の買取り価格相場`,
          linkUrl: `/ec/sell/item-list?keyword=${keyword}`
        });
        // カテゴリーとメーカーが指定されている時のみ、Canonicalを設定する
        if (categoryNm && makerNm) {
          CanonicalService.set(`${window.location.origin}/ec/sell/item-list?category=${categoryNm}&maker=${makerNm}`);
        }
      } else if (categoryNm && makerNm) {
        state.seoText = `${makerNm}${categoryNm}`;
        state.breadcrumbs.push({
          path: `${categoryNm}の買取り価格相場`,
          linkUrl: `/ec/sell/item-list?category=${categoryNm}`
        });
        state.breadcrumbs.push({
          path: `${makerNm}の買取り価格相場`,
          linkUrl: `/ec/sell/item-list?maker=${makerNm}`
        });
      } else if (makerNm || categoryNm) {
        state.seoText = `${makerNm}${categoryNm}`;
        const param = makerNm ? `?maker=${makerNm}` : `?category=${categoryNm}`;
        state.breadcrumbs.push({
          path: `${state.seoText}の買取り価格相場`,
          linkUrl: `/ec/sell/item-list${param}`
        });
      } else {
        state.breadcrumbs.push({
          path: `買取り価格相場`,
          linkUrl: ''
        });
      }

      // タイトル設定
      document.title = state.seoText ? `${state.seoText}の買取り価格相場|カメラのキタムラで高価買取り` : '買取り価格相場|カメラのキタムラで高価買取';
    };

    /**
     * 初期処理
     */
    const init = async () => {
      const query = context.root.$route.query;

      // 並び替え、表示件数を設定
      const setSort = state.sort.filter((item) => item.value === query.sort)[0];
      const setDisplayCount = state.displayCountList.filter((item) => item.value === +query.limit)[0];
      state.currentSort = setSort ? setSort.value : state.sort[0].value;
      if (setDisplayCount) {
        state.currentDisplayCount = setDisplayCount.value;
      } else {
        state.currentDisplayCount = context.root.$vuetify.breakpoint.lgAndUp ? state.displayCountList[1].value : state.displayCountList[0].value;
      }

      // 条件を設定
      delete query.sort;
      delete query.limit;
      state.currentConditions = SellService.deleteEmptyConditionParam(query as { [key: string]: string });

      // 各種設定
      state.isChangedCond = false;

      // インクルードファイルを取得
      const response = await IncludeFIleService.fetchIncludeFile('sell/item-list/banner.html');
      includeHtmlBanner.value = response;
    };

    /**
     * 検索結果の取得
     * @param isAdd 追加か
     */
    const searchProductList = async (isAdd = false) => {
      try {
        state.loaded.results = false;
        const result = await SellService.searchProductList(state.currentConditions, state.currentSort, state.currentDisplayCount, state.page);
        state.results = isAdd ? state.results.concat(result) : result;
      } catch (error) {
        console.error(error);
        state.results = [] as Array<ProductInfo>;
        state.totalCount = 0;
      } finally {
        state.loaded.results = true;
      }
    };

    /**
     * 検索結果件数の取得
     */
    const searchNewerProductCount = async () => {
      try {
        const resultCount = await SellService.searchProductListCount(state.currentConditions);
        state.totalCount = resultCount.total;
      } catch (error) {
        console.error(error);
        state.totalCount = 0;
      }
    };

    onMounted(() => {
      init();
    });

    /**
     * 現在の検索条件でURLを更新
     */
    const applyParamToURL = () => {
      let param = '';

      // 条件
      Object.entries(state.currentConditions).forEach(([key, val], index) => {
        const paramVal = val as string | Array<string>;
        if (typeof paramVal == 'string') {
          param = index === 0 ? param + '?' + key + '=' + paramVal : param + '&' + key + '=' + paramVal;
        } else {
          paramVal.forEach((item) => (param = index === 0 ? param + '?' + key + '=' + item : param + '&' + key + '=' + item));
        }
      });

      // 並び替え
      param = param ? param + '&sort=' + state.currentSort : '?sort=' + state.currentSort;
      // 表示件数
      param = param ? param + '&limit=' + state.currentDisplayCount : '?limit=' + state.currentDisplayCount;

      window.history.pushState(null, document.title, context.root.$route.path + param);
    };

    /**
     * 選択中条件のテキスト表示変換
     */
    const convertConditionText = (key: string) => {
      let target;
      switch (key) {
        case 'keyword':
          target = '商品名';
          break;
        case 'category':
          target = 'カテゴリー';
          break;
        case 'maker':
          target = 'メーカー';
          break;
        case 'tokutoku':
          target = 'トクトク対象';
          break;
        default:
          // target = SELL_CONDITIONS.find((cond) => cond.paramKey === key)?.paramText;
          target = key;
          break;
      }
      return target;
    };

    /** -------------------------------------------------------------
     * 監視対象
     ------------------------------------------------------------- */
    // 同一Vueへの遷移対応
    watch(
      () => context.root.$route,
      () => {
        init();
      }
    );

    // 検索条件情に基づく表示
    watch(
      () => [state.currentConditions, state.currentSort, state.currentDisplayCount],
      () => {
        state.page = 1;
        // カテゴリを取得
        state.currentCategory = (state.currentConditions.category as string) || '';

        if (state.isChangedCond) applyParamToURL();
        setSeoInfo();
        searchProductList();
        searchNewerProductCount();
      },
      { deep: true }
    );

    // 検索条件系ボタンの表示制御用
    watch(
      () => [sellCondition.savedSort],
      () => {
        if (sellCondition.savedSort) {
          state.emptySaveCondition = false;
        } else {
          state.emptySaveCondition = true;
          state.spDisplaySaveBtn = true;
        }
      },
      { immediate: true }
    );

    // SP用 検索結果件数０以下でドロワーを非表示に
    watch(
      () => [state.totalCount],
      () => {
        if (state.totalCount <= 0) {
          state.condtionDrawer = false;
          state.sortDrawer = false;
        }
      }
    );

    /**
     * 表示している件数の表示制御
     */
    const displayMaxCount = computed(() => {
      if (state.totalCount > state.currentDisplayCount * state.page) {
        return state.currentDisplayCount * state.page;
      } else {
        return state.totalCount;
      }
    });

    /** -------------------------------------------------------------
     * イベント
     ------------------------------------------------------------- */
    /**
     * スクロールイベント検出：「絞り込み」「並び替えボタン」の固定表示切り替え
     */
    const onScroll = () => {
      const headerHeight = header.headerHeight;
      if (state.productSearchConditions && state.conditionsBtnWrap) {
        if (headerHeight > state.productSearchConditions.clientHeight + state.productSearchConditions.getBoundingClientRect().top) {
          state.productSearchConditionsFixed = true;
          state.conditionsBtnWrap.style.top = headerHeight + 'px';
        } else {
          state.productSearchConditionsFixed = false;
          state.conditionsBtnWrap.style.top = '';
        }
      }
    };

    /**
     * 選択中条件の解除
     * @param key 条件キー
     */
    const removeCondition = (key: string) => {
      state.isChangedCond = true;
      context.root.$delete(state.currentConditions, key);
    };

    /**
     * もっと見る押下時の検索
     */
    const addSearch = () => {
      ++state.page;
      searchProductList(true);
    };

    /**
     * 検索条件の保存
     */
    const saveConditions = () => {
      sellCondition.save(state.currentConditions, state.currentSort, state.currentDisplayCount);
      state.tooltipSave = true;
      setTimeout(() => {
        // 3秒後に結果を非表示にする
        state.tooltipSave = false;
      }, 3000);
    };

    /**
     * 検索条件の削除
     */
    const deleteConditions = () => {
      sellCondition.remove();
    };

    /**
     * 検索条件の適用
     */
    const applyConditions = () => {
      state.isChangedCond = true;
      const storageCond = sellCondition.savedCondtion;
      const storageSort = sellCondition.savedSort;
      const storageDisplayCount = sellCondition.savedDisplayCount;
      if (Object.keys(storageCond).length) state.currentConditions = storageCond;
      if (storageSort) state.currentSort = storageSort;
      if (storageDisplayCount) state.currentDisplayCount = storageDisplayCount;
    };

    /** -------------------------------------------------------------
     * イベント(コンポーネント)
     ------------------------------------------------------------- */
    /**
     * 検索条件の変更
     * @param param 条件
     */
    const changeCondition = (param: { [key: string]: string }) => {
      state.isChangedCond = true;
      state.currentConditions = param;
    };

    /**
     * 見積り追加処理
     * @param goodsCode 商品コード
     * @param tradeinS 買取り価格
     */
    const add = (goodsCode: string, tradeinS: string) => {
      // storeに保存
      sellCondition.saveRecentCondtion(state.currentConditions, state.currentSort, state.currentDisplayCount);

      context.root.$router.push({
        name: 'sell-price-page',
        query: {
          goodsCode: goodsCode,
          tradeinS: tradeinS
        }
      });
    };

    return {
      includeHtmlBanner,
      ...toRefs(state),
      displayMaxCount,
      convertConditionText,
      onScroll,
      removeCondition,
      addSearch,
      saveConditions,
      deleteConditions,
      applyConditions,
      changeCondition,
      add
    };
  }
});
