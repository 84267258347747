






import Vue from 'vue';
import NotFound from '@/components/common/not-found.vue';

export default Vue.extend({
  name: 'not-found-page',
  components: {
    'not-found': NotFound
  }
});
