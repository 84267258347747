













































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import '@/styles/page.scss';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'other-news-20200601',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (_, context) => {
    document.title = '新型コロナウイルス感染予防に対する出張買取の対応 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', 'お客さまおよびスタッフの健康と安全を考慮し、出張買取の際に実施している感染予防対策をお知らせいたします')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'お知らせ',
          linkUrl: '/ec/page/service/sitemap'
        },
        {
          path: '新型コロナウイルス感染予防に対する出張買取の対応',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
