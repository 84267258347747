import { Maker } from '@/types/tsv-config';
import TsvConfigService from './tsv-config.service';

const ProductListConfigsService = {
  /**
   * メーカー設定を取得する
   */
  async fetchMakers(): Promise<Maker[]> {
    const localPath = `http://${window.location.host}/tsv/list/maker.tsv`;
    const path = `list/maker.tsv`;
    const response = await TsvConfigService.getTsv(path, {}, 0, localPath);
    return response;
  }
};

export default ProductListConfigsService;
