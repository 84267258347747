var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.breadcrumbs.length)?_c('breadcrumbs',{attrs:{"breadcrumbs":_vm.breadcrumbs}}):_vm._e(),_c('div',{staticClass:"main-content-area",class:{
      'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'container-narrow': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"id":"main-content"}},[_c('div',{staticClass:"service-guide"},[_c('h1',{staticClass:"mb-4 ml-4",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v(" サービス案内 ")]),_c('div',{staticClass:"bg-light-gray"},[_c('div',{staticClass:"service-guide--kaitori-guide"},[_c('div',{staticClass:"ec-back-color",class:{
              'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
              'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
            },attrs:{"name":"three-selling-ways"}},[_c('div',{staticClass:"ec-back-color",class:{
                'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
                'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
              }},[_c('span',{staticClass:"content-title",class:{
                  'content-subtitle-large': _vm.$vuetify.breakpoint.mdAndUp,
                  'content-subtitle-small': _vm.$vuetify.breakpoint.smAndDown
                }},[_vm._v("買取り方法")]),_c('three-selling-ways'),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('a',{staticClass:"way-card--outer",attrs:{"href":"/ec/page/service/houjin-kaitori"}},[_c('v-card',{staticClass:"mb-1 way-card rounded-xl",attrs:{"elevation":"0"}},[_c('p',{staticClass:"justify-center way-title"},[_vm._v("法人買取はこちら")]),_c('v-btn',{staticClass:"go-btn",attrs:{"icon":""}},[_c('v-icon',{attrs:{"color":"white","size":"30px"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)])],1)],1)])])]),_c('div',[_c('div',{staticClass:"ec-back-color",class:{
            'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
            'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
          },attrs:{"name":"three-selling-ways"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"content-title",class:{
                      'content-subtitle-large': _vm.$vuetify.breakpoint.mdAndUp,
                      'content-subtitle-small': _vm.$vuetify.breakpoint.smAndDown
                    }},[_vm._v("よくあるご質問")])]),_c('v-card-text',[_c('div',[_c('p',[_vm._v("よくあるご質問を掲載しておりますので、お問い合わせの前にご確認ください。")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"https://ecfaq.kitamura.jp/s/","target":"_blank"}},[_c('span',{staticClass:"btn-text"},[_vm._v("よくあるご質問")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)])])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"content-title",class:{
                      'content-subtitle-large': _vm.$vuetify.breakpoint.mdAndUp,
                      'content-subtitle-small': _vm.$vuetify.breakpoint.smAndDown
                    }},[_vm._v("お問い合わせ")])]),_c('v-card-text',[_c('div',[_c('p',[_vm._v("ネット中古へのお問い合わせはこちら。")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"/ec/page/guide/ask"}},[_c('span',{staticClass:"btn-text"},[_vm._v("お問い合わせ")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)])])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"content-title",class:{
                      'content-subtitle-large': _vm.$vuetify.breakpoint.mdAndUp,
                      'content-subtitle-small': _vm.$vuetify.breakpoint.smAndDown
                    }},[_vm._v("店舗規約、会員利用規約")])]),_c('v-card-text',[_c('div',[_c('p',[_vm._v("ご利用になる前にご一読ください。")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 col-12 text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"/ec/page/guide/kiyaku#used-kiyaku"}},[_c('span',{staticClass:"btn-text"},[_vm._v("中古サービス利用規約")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)]),_c('div',{staticClass:"col-md-6 col-12 text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"/ec/page/guide/kiyaku#shop-kiyaku"}},[_c('span',{staticClass:"btn-text"},[_vm._v("店舗規約")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)]),_c('div',{staticClass:"col-md-6 col-12 text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"/ec/page/guide/kiyaku#tokusyohou"}},[_c('span',{staticClass:"btn-text"},[_vm._v("特定商取引法に基づく表示")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)]),_c('div',{staticClass:"col-md-6 col-12 text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"https://www.kitamura.co.jp/policy/agreement.html?_ga=2.256903688.2083294966.1613955741-1830652927.1612760899","target":"_blank"}},[_c('span',{staticClass:"btn-text"},[_vm._v("会員利用規約")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)])])])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-2",attrs:{"tile":"","outlined":""}},[_c('v-card-title',[_c('span',{staticClass:"content-title",class:{
                      'content-subtitle-large': _vm.$vuetify.breakpoint.mdAndUp,
                      'content-subtitle-small': _vm.$vuetify.breakpoint.smAndDown
                    }},[_vm._v("会社概要")])]),_c('v-card-text',[_c('div',[_c('p',[_vm._v("企業情報をご案内いたします。")]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"block btn-normal btn-white-green",attrs:{"href":"https://www.kitamura.co.jp/info/?_ga=2.153226939.2083294966.1613955741-1830652927.1612760899","target":"_blank"}},[_c('span',{staticClass:"btn-text"},[_vm._v("会社概要")]),_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)])])])],1)],1)],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }