import { AxiosRequestConfig } from 'axios';
import ApiService from './api.service';
import { isLocalHost } from '@/logic/utils';

const IncludeFileService = {
  /**
   * インクルードファイルを取得
   */
  async fetchIncludeFile(path: string): Promise<string> {
    const url = process.env.VUE_APP_INCLUDE_FILE_BASE_URL + path;
    const config: AxiosRequestConfig = {};
    config.responseType = 'text';
    const response = await ApiService.get(url, config);

    return response;
  },

  /**
   * 静的HTMLファイルを取得
   */
  async fetchStaticHtmlFile(path: string): Promise<any> {
    let url = path;
    if (isLocalHost()) {
      url = path.includes('localhost') ? path.replace('localhost:8080', 'nc-feature9.kitamura.shop') : 'https://nc-feature9.kitamura.shop' + path;
    }
    const config: AxiosRequestConfig = {};
    config.responseType = 'text';
    const response = await ApiService.get(url, config, true, false, true);

    return response;
  }
};

export default IncludeFileService;
