






import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import SectionLoading from '@/components/common/section-loading.vue';
import SellService from '@/logic/sell.service';

export default Vue.extend({
  name: 'temp-mitsumori-page',
  components: {
    'section-loading': SectionLoading
  },
  metaInfo: () => {
    return {
      title: 'カメラのキタムラ ネット中古 | 買取り方法選択'
    };
  },
  setup: (_, context) => {
    const { loader, sellEstimate } = context.root.$store;
    const state = reactive({
      loaded: true
    });
    const query = context.root.$route.query;

    onMounted(async () => {
      loader.routeUnset();
      if (query.id && query.price) {
        await SellService.offerPackagingKit([
          {
            janCode: '',
            itemName: '',
            makerName: '',
            imagePath: '',
            price: +(query.price as string),
            goodsCode: query.id as string
          }
        ]);
        const redirectURL =
          typeof query.serviceId === 'string' && query.serviceId ? `/sell/direct/init.do?serviceId=${query.serviceId}` : '/sell/direct/init.do?serviceId=50';
        window.location.href = redirectURL;
        sellEstimate.clearPackageKitEstimate();
      } else {
        // クエリーがない場合は、エラーページへ遷移する
        context.root.$router.push({ name: 'not-found-page' });
      }
      loader.routeLoaded();
    });

    return {
      ...toRefs(state)
    };
  }
});
