










































































































































































































































































































































































































































































































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import '@/styles/page.scss';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'guide-kiyaku',
  components: {
    breadcrumbs: Breadcrumbs
  },
  setup: (_, context) => {
    document.title = '中古サービス利用規約 | カメラのキタムラ ネット中古'
    document.querySelector<any>('meta[name="description"]').setAttribute('content', '買取りをご利用を希望される、お客様と当社との間で適用されるサービス規約です')

    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
    });

    onMounted(() => {
      // 各種設定
      state.breadcrumbs = [
        {
          path: 'ネット中古TOP',
          linkUrl: '/'
        },
        {
          path: 'ご利用案内',
          linkUrl: '/ec/page/guide/sitemap'
        },
        {
          path: '中古サービス利用規約',
          linkUrl: ''
        },
      ];
    });

    return {
      ...toRefs(state),
    };
  }
});
