











import Vue from 'vue';
import { ref, toRefs, reactive, onMounted, onUpdated } from '@vue/composition-api';
import NotFound from '@/components/common/not-found.vue';
import IncludeFileService from '@/logic/include-file.service';
import { validationPeriod, isDebugMode } from '@/logic/utils';
import GaService from '@/logic/ga.service';
import SectionLoading from '@/components/common/section-loading.vue';

export default Vue.extend({
  name: 'html-page',
  components: {
    'not-found': NotFound,
    'section-loading': SectionLoading
  },
  setup: (props, context) => {
    const route = context.root.$route;
    const query = context.root.$route.query;
    const includeHtml = ref<string>('');

    const state = reactive({
      // 表示期間外メッセージ
      errorMsg: '',
      // 読み込み済み
      htmlLoaded: false
    });

    // インクルードファイルを取得
    const path = route.path.replace('ec', 'ec/static-html');
    IncludeFileService.fetchStaticHtmlFile(path)
      .then(async (response) => {
        const dom = new DOMParser().parseFromString(response.data, 'text/html');

        const titles = dom.getElementsByTagName('title');
        if (titles && titles[0].textContent?.toString()) {
          document.title = titles[0].textContent?.toString();
        }

        // GAクロスドメイン指定をする
        // ただし、GAが読み込めなかった場合はスキップする
        const links = dom.getElementsByName('gaLink');
        let gaLoaded = GaService.isLoadedGa();
        if (links.length > 0 && !gaLoaded) {
          gaLoaded = await GaService.waitInitGa();
        }
        if (gaLoaded) {
          for (let i = 0; i < links.length; i++) {
            await GaService.setGaForLink(links[i]).then();
          }
        }

        // 表示期間制御
        const today = isDebugMode() && query.date ? `${query.date}` : response.headers.date;
        const from = dom.getElementById('validFrom')?.textContent?.toString();
        const to = dom.getElementById('validTo')?.textContent?.toString();
        if (validationPeriod(today, from, to)) {
          const styles = dom.querySelectorAll('style');
          let style = '';
          styles.forEach((elem) => (style += elem.outerHTML));
          const html = dom.getElementById('contents')?.innerHTML || '';
          includeHtml.value = style + html;
        } else {
          state.errorMsg = '該当ページは表示期間外です';
        }
      })
      .catch(() => {
        state.errorMsg = '指定されたページが見つかりませんでした';
      })
      .finally(() => {
        // HTMLの読み込みが完了
        state.htmlLoaded = true;
      });

    /**
     * iframeを埋め込む。
     * 別の静的ページを参照しているものは、中身のみを埋め込む（これをしないと、ヘッダ／フッタが含まれた状態で埋め込まれるため）。
     */
    const embedIframe = async () => {
      const iframes = context.root.$el.querySelectorAll('iframe');
      iframes.forEach(async (iframe) => {
        const parentNode = iframe.parentNode;
        if (parentNode) {
          if (iframe.src.endsWith('.html')) {
            const alternativeNode = document.createElement('div');
            alternativeNode.className = iframe.className;
            const iframeResponse = await IncludeFileService.fetchStaticHtmlFile(iframe.src);
            alternativeNode.innerHTML = iframeResponse.data;
            parentNode.replaceChild(alternativeNode, iframe);
          }
        }
      });
    };

    /**
     * v:htmlだとスクリプトが実行されないため、個別に実行する。
     */
    const runScript = () => {
      const scripts = context.root.$el.querySelectorAll('script');
      scripts.forEach((script) => {
        const parentNode = script.parentNode;
        const alternativeNode = document.createElement('script');
        if (parentNode) {
          if (script.src) {
            alternativeNode.src = script.src;
          } else {
            alternativeNode.textContent = script.textContent;
          }
          parentNode.replaceChild(alternativeNode, script);
        }
      });
    };

    /**
     * ページ内遷移を行う。
     */
    const scroll = () => {
      if (location.hash) {
        // レンダリングが完了するまでサイズが安定しないため、少し待つ
        setTimeout(() => {
          const id = location.hash.replace(/^#/g, '');
          const targetDom = document.getElementById(id);
          if (targetDom) {
            if ('scrollBehavior' in document.documentElement.style) {
              return window.scrollTo({ top: targetDom.offsetTop, behavior: 'smooth' });
            } else {
              return window.scrollTo(0, targetDom.offsetTop);
            }
          }
        }, 100);
      }
    };

    /**
     * HTMLレンダリング時に行う処理。
     */
    const onRender = () => {
      embedIframe();
      runScript();
      scroll();
    };

    onMounted(() => onRender());
    onUpdated(() => onRender());

    return {
      ...toRefs(state),
      includeHtml
    };
  }
});
