





















































import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-floating-btn',
  props: ['tel1', 'tel2', 'line'],
  data() {
    return {
      syuttyouKaitoriLp: {
        tel1: this.tel1,
        tel2: this.tel2,
        line: this.line,
      },
      scrollY: 0, //スクロールで表示・非表示
      lastScrollY: 0,  // 前回のスクロール位置を保持するための変数
      visible: true //スクロールで表示・非表示
    };
  },
  /* スクロールで表示・非表示 */
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;

      const reachedBottom = (window.innerHeight + this.scrollY) >= document.body.offsetHeight;

      if (reachedBottom) {
        this.visible = false;
      } else if (this.scrollY < this.lastScrollY) {  // 上にスクロールした場合
        this.visible = true;
      }

      this.lastScrollY = this.scrollY;  // 現在のスクロール位置をlastScrollYに保持
    },
  },
  /* /スクロールで表示・非表示 */
});
