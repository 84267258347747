








































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'sellingPd',
  components: {},
  setup: () => {
    const state = reactive({
      model: null,
      items: [
        {
          title: '交換レンズ',
          src: 'selling-pd/interchangable.png',
          id: 0,
          url: '/ec/sell/item-list?category=交換レンズ',
          target: '_self'
        },
        {
          title: 'デジタル一眼',
          src: 'selling-pd/digital-single-eye.png',
          id: 1,
          url: '/ec/sell/item-list?category=デジタル一眼レフ',
          target: '_self'
        },
        {
          title: 'ミラーレス一眼',
          src: 'selling-pd/mirrorless-interchange.png',
          id: 2,
          url: '/ec/sell/item-list?category=ミラーレス一眼',
          target: '_self'
        },
        {
          title: 'コンパクトデジタルカメラ',
          src: 'selling-pd/compact-digital.png',
          id: 3,
          url: '/ec/sell/item-list?category=コンパクトデジタルカメラ',
          target: '_self'
        },
        {
          title: 'フィルムカメラ',
          src: 'selling-pd/film.png',
          id: 4,
          url: '/ec/sell/item-list?category=フィルムカメラ',
          target: '_self'
        },
        {
          title: 'デジタルビデオカメラ',
          src: 'selling-pd/digital-video.png',
          id: 5,
          url: '/ec/sell/item-list?category=デジタルビデオカメラ',
          target: '_self'
        },
        {
          title: 'ビデオカメラ',
          src: 'selling-pd/video.png',
          id: 6,
          url: '/ec/sell/item-list?category=ビデオカメラ',
          target: '_self'
        },
        {
          title: 'カメラ用品',
          src: 'selling-pd/camera-supplies.png',
          id: 7,
          url: '/ec/sell/item-list?category=カメラ用品',
          target: '_self'
        },
        {
          title: '三脚',
          src: 'selling-pd/tripod.png',
          id: 8,
          url: '/ec/sell/item-list?category=三脚',
          target: '_self'
        },
        {
          title: 'スマホ',
          src: 'selling-pd/smartphone.png',
          id: 9,
          url: 'https://shop.kitamura.jp/smartphone/sell/',
          target: '_blank'
        },
        {
          title: '腕時計',
          src: 'selling-pd/watch.png',
          id: 10,
          url: 'https://shop.kitamura.jp/watch/sell/',
          target: '_blank'
        },
        {
          title: 'ブランド品・貴金属買取',
          src: 'selling-pd/brand.png',
          id: 11,
          url: 'https://shop.kitamura.jp/lp/brand-01/',
          target: '_blank'
        }
      ]
    });

    function getImgUrl(pic: string) {
      return require('../assets/' + pic);
    }

    return {
      ...toRefs(state),
      getImgUrl
    };
  }
});
