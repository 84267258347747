































































import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-step',
  data() {
    return {
      syuttyouKaitoriSteps: [
        {
          number: '1',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/step01.png',
          title: '出張買取お申込み',
          text: 'まずは「出張買取受付窓口」にお電話ください。\n買取り対象品であるかの確認と事前査定でご安心いただけます。'
        },
        {
          number: '2',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/step02.png',
          title: '買取り日の申込み',
          text: 'お客さまとご相談後、出張買取日を決めさせていただきます。\nもちろん当日出張も可能です！\nご希望日を３つ程いただけるとスムーズに対応可能です。'
        },
        {
          number: '3',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/step03.png',
          title: '出張買取当日',
          text: '当日、お客さまのご自宅へお伺いし、査定をいたします。\n※「法人買取り」の場合のみ、職場へお伺いし、査定をいたします'
        },
        {
          number: '4',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/step04.png',
          title: '買取り成立',
          text: '査定金額にご納得いただけましたら、その場で現金にてお品物を買取りいたします。\nその際、身分証明書のご提示をお願いいたしております。',
          certificate: true
        }
      ]
    };
  },
});
