var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-content-area",class:{
    'container-wide': _vm.$vuetify.breakpoint.mdAndUp,
    'container-narrow': _vm.$vuetify.breakpoint.smAndDown
  },attrs:{"id":"sub-content"}},[(_vm.isTopThreeSellingWays)?_c('div',{staticClass:"ec-back-color",class:{
      'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"three-selling-ways"}},[_c('div',{staticClass:"ec-back-color",class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('h2',{staticClass:"content-title",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v(" "+_vm._s(_vm.topThreeSellingWaysTitle)+" ")]),_c('three-selling-ways',{attrs:{"seoTag":true}})],1)]):_vm._e(),(_vm.isDisplayBanner)?_c('div',{class:{
      'banner-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'banner-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('div',{attrs:{"id":"sell-sub-contents-banner"},domProps:{"innerHTML":_vm._s(_vm.includeHtmlBanner)}})]):_vm._e(),(_vm.isDisplayHighPricePd)?_c('div',{staticClass:"ec-back-color",class:{
      'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow full-width': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"high-price-pd"}},[_c('div',{class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('high-price-pd')],1)]):_vm._e(),(_vm.isDisplayPublicRelations)?_c('div',{class:{
      'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"pd"}},[_c('div',{attrs:{"id":"sell-sub-contents-pd"},domProps:{"innerHTML":_vm._s(_vm.includeHtmlPd)}})]):_vm._e(),(_vm.isThreeSellingWays)?_c('div',{staticClass:"ec-back-color",class:{
      'content-area-outer-wide py-10': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow py-8': _vm.$vuetify.breakpoint.smAndDown
    },attrs:{"name":"three-selling-ways"}},[_c('div',{staticClass:"ec-back-color",class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('span',{staticClass:"content-title",class:{
          'content-title-large': _vm.$vuetify.breakpoint.mdAndUp,
          'content-title-small': _vm.$vuetify.breakpoint.smAndDown
        }},[_vm._v("3つの買取り方法")]),_c('three-selling-ways')],1)]):_vm._e(),(_vm.isDisplayQuickAssessment)?_c('div',{staticClass:"free-quick-assessment",class:{
      'content-area-outer-wide': _vm.$vuetify.breakpoint.mdAndUp,
      'content-area-outer-narrow': _vm.$vuetify.breakpoint.smAndDown
    }},[_c('div',{class:{
        'content-area-wide': _vm.$vuetify.breakpoint.mdAndUp,
        'content-area-narrow': _vm.$vuetify.breakpoint.smAndDown
      }},[_c('div',{staticClass:"free-quick-assessment-text"},[_vm._v("検索に迷われたら･･･")]),_c('div',{staticClass:"free-quick-assessment-title"},[_vm._v("無料クイック査定")]),_vm._m(0),_c('div',{staticClass:"free-quick-assessment-img-area"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('img',{staticClass:"free-quick-assessment-img",attrs:{"src":require("@/assets/free-quick-assessment/free-quick-assessment-pc.png"),"alt":"査定を依頼/折り返し連絡","width":"1029","height":"269"}}):(_vm.$vuetify.breakpoint.smAndDown)?_c('img',{staticClass:"free-quick-assessment-img",attrs:{"src":require("@/assets/free-quick-assessment/free-quick-assessment-sp.png"),"alt":"査定を依頼/折り返し連絡","width":"355","height":"103"}}):_vm._e(),_vm._m(1)]),_c('v-btn',{staticClass:"free-quick-assessment-btn mx-auto",attrs:{"block":"","depressed":"","href":"/ec/sell"}},[_c('span',{staticClass:"btn-text"},[_vm._v("無料クイック査定依頼はこちら "),_c('v-icon',{staticClass:"free-quick-assessment-btn-icon",attrs:{"small":"","right":""}},[_vm._v("fas fa-chevron-right")])],1)])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"free-quick-assessment-text"},[_vm._v("お客さまは入力するだけ！"),_c('br'),_vm._v("査定専門スタッフがお客さまに最適な情報をご案内")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"free-quick-assessment-img-desc"},[_c('div',{staticClass:"free-quick-assessment-img-desc-text"},[_vm._v(" お客さま情報と商品情報を入力"),_c('span',{staticClass:"free-quick-assessment-img-desc-note"},[_vm._v("※商品名がご不明な場合は、空欄のままで大丈夫です")])]),_c('div',{staticClass:"free-quick-assessment-img-desc-text"},[_vm._v("査定専門スタッフが査定額目安や最適な情報をご案内")])])}]

export { render, staticRenderFns }