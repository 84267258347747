















































import Vue from 'vue';
import { reactive, toRefs, onMounted } from '@vue/composition-api';
import Breadcrumbs from '@/components/common/breadcrumbs.vue';
import SectionLoading from '@/components/common/section-loading.vue';
import NotFound from '@/components/common/not-found.vue';
import { News } from '@/types/news';
import { formatDate } from '@/logic/utils';
import NewsService from '@/logic/news.service';
import dayjs from 'dayjs';

type BreadcrumbItem = {
  path: string;
  linkUrl: string;
};

export default Vue.extend({
  name: 'news-detail-page',
  components: {
    breadcrumbs: Breadcrumbs,
    'section-loading': SectionLoading,
    'not-found': NotFound
  },
  setup: (_, context) => {
    const today = dayjs(new Date());
    const state = reactive({
      // パンくず
      breadcrumbs: [] as Array<BreadcrumbItem>,
      // 比較日（クエリパラメーター「at」で指定可能、それ以外は本日）
      comparisonDate: today,
      // 現在表示しているお知らせ
      currentNewsId: '',
      // お知らせ詳細
      newsDetail: {} as News,
      // ロード状態
      loaded: {
        newsDetail: false
      },
      // 表示期間外メッセージ
      errorMsg: ''
    });

    /** -------------------------------------------------------------
     * news詳細取得
     ------------------------------------------------------------- */
    /**
     * news一覧取得
     */
    const searchNewsDetail = async () => {
      state.loaded.newsDetail = false;
      try {
        const newsResult = await NewsService.searchNews(false, {
          newsNo: state.currentNewsId,
          newsType: []
        });
        if (newsResult.length) {
          state.newsDetail = newsResult[0];

          // 未来日付は、「期間外」表示にする（クエリパラメーター指定時は、その日までであれば表示）
          const newsDate = dayjs(state.newsDetail.newsDate);
          if (state.comparisonDate.diff(newsDate, 'day') < 0) {
            state.errorMsg = '該当ページは掲載期間が終了いたしました。';
            return;
          }

          document.title = 'カメラのキタムラ ネット中古 | ' + state.newsDetail.newsTitle;
          state.breadcrumbs = [
            {
              path: 'ネット中古TOP',
              linkUrl: '/'
            },
            {
              path: 'ニュース一覧',
              linkUrl: '/ec/news'
            },
            {
              path: state.newsDetail.newsTitle,
              linkUrl: ''
            }
          ];
        } else {
          throw 'newsResult.length 0';
        }
      } catch (error) {
        console.error(error);
        state.newsDetail = {} as News;
        context.root.$router.push({ name: 'not-found-page' });
      } finally {
        state.loaded.newsDetail = true;
      }
    };

    const init = async () => {
      const params = context.root.$route.params;
      const query = context.root.$route.query;

      // クエリパラメーターでの日付指定
      if (query.at) {
        state.errorMsg = '';
        const at = query.at as string;
        state.comparisonDate = dayjs(at);
      }

      // idが無い場合は、NotFoundPageに遷移させる
      if (params.id) {
        state.currentNewsId = `${params.id}`;
        await searchNewsDetail();
      } else {
        context.root.$router.push({ name: 'not-found-page' });
      }
    };

    onMounted(() => {
      init();
    });

    const goNewsList = (year: string) => {
      context.root.$router.push({ path: `/ec/news/${year}` });
    };

    return {
      ...toRefs(state),
      formatDate,
      goNewsList
    };
  }
});
