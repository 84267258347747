

























import Vue from 'vue';
export default Vue.extend({
  name: 'syuttyou-kaitori-point',
  data() {
    return {
      syuttyouKaitoriPoints: [
        {
          number: '1',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/point01.png',
          title: 'キタムラ社員が訪問',
          text: '経験豊富なカメラのキタムラ社員が訪問し、査定をいたします'
        },
        {
          number: '2',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/point02.png',
          title: 'その場で現金買取り',
          text: '査定額にご納得いただければ、その場で現金買取りいたします\n※買取金額により変わる場合もございます'
        },
        {
          number: '3',
          url: '/ec/images2/pages/service/syuttyou-kaitori/by-area/point03.png',
          title: 'お見積り・キャンセル無料',
          text: 'お見積り・キャンセル無料ですので、安心してご依頼ください'
        }
      ]
    };
  },
});
