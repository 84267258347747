









































import Vue from 'vue';
import { reactive, toRefs, PropType } from '@vue/composition-api';
import { SellProductDetail } from '@/types/sell-product-detail';
import { noimage } from '@/logic/utils';

export default Vue.extend({
  name: 'product-info',
  props: {
    // カテゴリー名
    categoryNm: {
      required: true,
      type: String,
      default: ''
    },
    // 商品情報
    product: {
      required: true,
      type: Object as PropType<SellProductDetail>
    }
  },
  setup: () => {
    const state = reactive({});

    const clickQuickAssessment = () => {
      window.location.href = '/#quick-assessment-link';
    };

    return {
      ...toRefs(state),
      noimage,
      clickQuickAssessment
    };
  }
});
