





































































import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';
import UserMenu from '@/components/header/user-menu.vue';
import NavDrawerMenu from '@/components/header/nav-drawer-menu/nav-drawer-menu.vue';

export default Vue.extend({
  name: 'header-top',
  components: {
    'user-menu': UserMenu,
    'nav-drawer-menu': NavDrawerMenu
  },
  props: {
    welcomeHide: {
      type: Boolean,
      required: false
    },
    narrowSlideFixed: {
      type: Boolean,
      required: false
    }
  },
  setup: () => {
    const state = reactive({
      drawer: false
    });

    return {
      ...toRefs(state)
    };
  }
});
