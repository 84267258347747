import { RouteConfig } from 'vue-router';

import SellList from '@/components/my-page/sell-list/sell-list.vue';
import SellDetail from '@/components/my-page/sell-detail/sell-detail.vue';

const routes: Array<RouteConfig> = [
  {
    path: 'sell/list',
    name: 'my-page-sell-list',
    component: SellList,
    meta: { gtm: 'MyPageSellList' }
  },
  {
    path: 'sell/:estimateId',
    name: 'my-page-sell-detail',
    component: SellDetail,
    meta: { gtm: 'MyPageSellDetail' }
  }
];

export default routes;
