import { Prefecture, Category, Maker, ProductInfo, AssessmentItem } from '@/shims-db';
import ApiService from './api.service';
import { generateImagePath, replaceSearchText } from '@/logic/utils';
import { SuggestItem } from '@/types/suggest';

const SuggestService = {
  /**
   * 「新品・中古」／「新品のみ」でサジェストするときにも使用
   * @param category 検索するときに絞り込み条件となるカテゴリ
   * @param keyword 検索キーワード
   * @param includeUsed 「新品のみ」：false 「中古のみ」：true。
   */
  async searchNewItem(category: string, keyword: string, includeUsed?: boolean): Promise<SuggestItem> {
    const url = process.env.VUE_APP_API_VIVACE_NEW_BASE_URL + 'suggest';
    let response = {} as SuggestItem;

    if (includeUsed) {
      response = await ApiService.get(url, {
        params: { category, keyword: replaceSearchText(keyword), narrow18: 2, sort: 'keyword' }
      });
    } else {
      response = await ApiService.get(url, { params: { category, keyword: replaceSearchText(keyword), sort: 'keyword' } });
    }

    return response;
  },

  /**
   * 「中古のみ」でサジェストするときにも使用
   * @param category 検索するときに絞り込み条件となるカテゴリ
   * @param keyword 検索キーワード
   */
  async searchUsedItem(category: string, keyword: string): Promise<SuggestItem> {
    const url = process.env.VUE_APP_API_VIVACE_USED_BASE_URL + 'suggest';
    const response = await ApiService.get(url, {
      params: { category, keyword: replaceSearchText(keyword), sort: 'keyword' }
    });
    return response;
  },

  /**
   * 「買取り」でサジェストするときにも使用
   * @param category 検索するときに絞り込み条件となるカテゴリ
   * @param keyword 検索キーワード
   */
  async searchSellItem(category: string, keyword: string): Promise<SuggestItem> {
    const url = process.env.VUE_APP_API_VIVACE_QUOTATION_BASE_URL + 'suggest';
    const response = await ApiService.get(url, {
      params: { category, keyword: replaceSearchText(keyword), sort: 'keyword' }
    });
    return response;
  },

  async getPrefecture(brand: number): Promise<Prefecture[]> {
    const url = process.env.VUE_APP_API_SHOP_BASE_URL + 'api/shop/kens';
    const config = {
      params: { brand }
    };
    const response = await ApiService.get(url, config, false);
    return response.kens;
  },

  async getMunicipalityByCode(brand: number, kenCode: number): Promise<any[]> {
    const url = process.env.VUE_APP_API_SHOP_BASE_URL + 'api/shop/citiesByKen';
    const config = {
      params: { brand, kenCode }
    };
    const response = await ApiService.get(url, config, false);
    return response.cities;
  },

  async getQuotation(): Promise<any> {
    const url = process.env.VUE_APP_API_VIVACE_QUOTATION_BASE_URL + 'count';
    const response = await ApiService.get(url, {});
    return response;
  },
  getCategory(categories: any) {
    const categoryList: Category[] = [];
    for (const key in categories) {
      const isParentCategory = key.indexOf(':') === -1;
      if (isParentCategory && Object.prototype.hasOwnProperty.call(categories, key)) {
        const category: Category = {
          category: key,
          showText: key + ' (' + categories[key] + ')',
          items: categories[key]
        };
        if (key !== '0') {
          categoryList.push(category);
        }
      }
    }
    return categoryList.sort((firstItem, secondItem) => (firstItem.items > secondItem.items ? -1 : 1));
  },

  getMakerList(makerData: any) {
    const makerList: Maker[] = [];
    if (makerData) {
      for (const key in makerData) {
        if (Object.prototype.hasOwnProperty.call(makerData, key)) {
          const maker: Maker = {
            name: key,
            showText: key + ' (' + makerData[key] + ')',
            items: makerData[key]
          };
          if (key !== '0') {
            makerList.push(maker);
          }
        }
      }
      return makerList.sort((firstItem, secondItem) => (firstItem.items > secondItem.items ? -1 : 1));
    }
    return makerList;
  },

  async getHighPriceProductList(code: Array<string>) {
    const url = `${process.env.VUE_APP_API_VIVACE_QUOTATION_BASE_URL}list?keyword=${code.join(',')}`;
    const response = await ApiService.get(url);

    let productList: ProductInfo[] = [];
    if (response) {
      response.wordItems.forEach((wordItem: { items: ProductInfo[]; keyword: string }) => {
        wordItem.items.forEach((product: ProductInfo) => {
          if (code.includes(product.goods_code)) productList.push(product);
        });
      });
    }

    // 表示できないデータを除外
    productList = productList.filter((item) => {
      return item.title && item.list_price && item.jan_code;
    });

    // 画像指定がない場合、no imageに差し替え
    productList.forEach((product) => {
      product.image = generateImagePath(product.image);
    });

    return productList;
  },

  async priorAccessAssessment() {
    const url = process.env.VUE_APP_ASSESSMENT_URL + 'sell/quick-assessment-complete2.do';
    try {
      const response = await ApiService.get(url);
    } catch (error) {
      // APIからの応答でCORSエラーが起きても無視する
      console.error(error);
    }
  },

  async applyAssessment(itemsList: AssessmentItem[], kenCode: any, lastName: string, firstName: string, phone: any) {
    const url = process.env.VUE_APP_ASSESSMENT_URL + 'sell/quick-assessment2.do';
    let bodyString = '';
    itemsList.forEach((item, index) => {
      bodyString +=
        'gk0' + (index + 1) + '=' + item.category + '&gm0' + (index + 1) + '=' + item.manufacturer + '&gn0' + (index + 1) + '=' + item.itemName + '&';
    });
    bodyString += 'ken=' + kenCode + '&ln=' + lastName + '&fn=' + firstName + '&tel=' + phone;
    const response = await ApiService.post(url, bodyString);
  }
};

export default SuggestService;
