






































import Vue from 'vue';
import { reactive, toRefs, watch } from '@vue/composition-api';

import SnsLink from '@/components/footer/sns-link.vue';
import ServiceLink from '@/components/footer/service-link.vue';
import ProductLink from '@/components/footer/product-link.vue';
import CopyRight from '@/components/footer/copyright.vue';
import BackToTop from '@/components/footer/back-to-top.vue';
import FabPhone from '@/components/footer/fab-phone.vue';
import FabSearch from '@/components/footer/fab-search.vue';

export default Vue.extend({
  name: 'app-footer',
  components: {
    'sns-link': SnsLink,
    'service-link': ServiceLink,
    'product-link': ProductLink,
    'copy-right': CopyRight,
    'back-to-top': BackToTop,
    'fab-phone': FabPhone,
    'fab-search': FabSearch
  },
  setup: (_, context) => {
    const state = reactive({
      isDisplayFabSearch: false
    });

    watch(
      () => context.root.$route.name,
      () => {
        state.isDisplayFabSearch = context.root.$route.name === 'top-page' ? true : false;
      },
      { immediate: true }
    );

    return {
      ...toRefs(state)
    };
  }
});
