import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
import VueCookies from 'vue-cookies';
import VueMeta from 'vue-meta';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import App from './main.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import globalStore from './store/global';
import ApiService from './logic/api.service';
import './styles/common.scss';

// Polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { formatPrice, formatDate } from './logic/utils';

Vue.use(VueCompositionApi);
Vue.config.productionTip = false;
Vue.prototype.$store = globalStore();
Vue.use(VueCookies);
Vue.use(VueMeta);

dayjs.extend(isBetween);

ApiService.init();

Vue.filter('date', formatDate);
Vue.filter('price', formatPrice);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
