


















import Vue from 'vue';
import { reactive, toRefs } from '@vue/composition-api';

export default Vue.extend({
  name: 'pr',
  components: {},
  setup: () => {
    const state = reactive({
      model: null
    });

    return {
      ...toRefs(state)
    };
  }
});
