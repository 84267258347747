







































import Vue from 'vue';
import { reactive, toRefs, onBeforeMount } from '@vue/composition-api';

export interface NavDrawerSubMenuContent {
  title: string;
  link: string;
  subContents: NavDrawerSubMenuContent[];
}

export default Vue.extend({
  name: 'nav-drawer-sub-menu',
  components: {},
  props: {
    selectSubMenu: {
      type: Array,
      required: true
    }
  },
  setup: (props, context) => {
    // データ格納
    const state = reactive<any>({
      subMenus: props.selectSubMenu,
      itemsCount: [],
      itemsPlusCount: [],
      expand: [],
      expandTitle: []
    });
    onBeforeMount(() => {
      state.subMenus.forEach(function(_: any, index: number) {
        if (state.subMenus[index].plus) {
          state.expand[index] = false;
          state.expandTitle[index] = 'もっと見る +';
          state.itemsCount[index] = state.subMenus[index].items.length;
          state.itemsPlusCount[index] = state.subMenus[index].itemsPlus.length;
        }
      });
    });
    // もっと見る
    const open = (subMenu: any, index: number) => {
      state.expandTitle[index] = '閉じる -';
      // リスト追加
      subMenu.itemsPlus.forEach(function(v: any) {
        subMenu.items.push(v);
      });
    };
    // 閉じる
    const close = (subMenu: any, index: number) => {
      state.expandTitle[index] = 'もっと見る +';
      // リスト削除
      subMenu.items.splice(state.itemsCount[index], state.itemsPlusCount[index]);
    };
    // クリック
    const onClick = (subMenu: any, index: number) => {
      state.expand[index] = !state.expand[index];
      if (state.expand[index]) {
        open(subMenu, index);
      } else {
        close(subMenu, index);
      }
    };
    // リンクのクリック操作
    const onLinkClick = (linkType: 'external' | 'internal', url: string): void => {
      switch (linkType) {
        case 'external':
          window.location.href = url;
          break;
        case 'internal':
          context.root.$router.push({ path: url });
          break;
      }
    };

    const backToMainMenu = () => {
      context.emit('back-to-main-event');
    };

    return {
      ...toRefs(state),
      onClick,
      onLinkClick,
      open,
      close,
      backToMainMenu
    };
  }
});
